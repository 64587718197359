
@charset "utf-8";

$text:#333333;
$line:#c2c2c2;
$ease1:cubic-bezier(0, 1.63, 0.78, 0.96);
$base:#E9F5EF;
$kon:#004097;
$px: 0.267vw;

@mixin sp {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin gd-OR{
  background : -moz-linear-gradient(0% 50% 0deg,rgba(241, 150, 0, 1) 0%,rgba(232, 85, 4, 1) 100%);
  background : -webkit-linear-gradient(0deg, rgba(241, 150, 0, 1) 0%, rgba(232, 85, 4, 1) 100%);
  background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(241, 150, 0, 1) ),color-stop(1,rgba(232, 85, 4, 1) ));
  background : -o-linear-gradient(0deg, rgba(241, 150, 0, 1) 0%, rgba(232, 85, 4, 1) 100%);
  background : -ms-linear-gradient(0deg, rgba(241, 150, 0, 1) 0%, rgba(232, 85, 4, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#F19600', endColorstr='#E85504' ,GradientType=0)";
  background : linear-gradient(90deg, rgba(241, 150, 0, 1) 0%, rgba(232, 85, 4, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F19600',endColorstr='#E85504' , GradientType=1);
}


@mixin gd-GR{
  background : -moz-linear-gradient(21% 231.25% 45deg,rgba(189, 222, 193, 1) 0%,rgba(136, 200, 150, 1) 100%);
  background : -webkit-linear-gradient(45deg, rgba(189, 222, 193, 1) 0%, rgba(136, 200, 150, 1) 100%);
  background : -webkit-gradient(linear,21% 231.25% ,79% -131.25% ,color-stop(0,rgba(189, 222, 193, 1) ),color-stop(1,rgba(136, 200, 150, 1) ));
  background : -o-linear-gradient(45deg, rgba(189, 222, 193, 1) 0%, rgba(136, 200, 150, 1) 100%);
  background : -ms-linear-gradient(45deg, rgba(189, 222, 193, 1) 0%, rgba(136, 200, 150, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#BDDEC1', endColorstr='#88C896' ,GradientType=0)";
  background : linear-gradient(45deg, rgba(189, 222, 193, 1) 0%, rgba(136, 200, 150, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#BDDEC1',endColorstr='#88C896' , GradientType=1);
}

@mixin gd-BLU{
  background : -moz-linear-gradient(20% 200% 45deg,rgba(126, 206, 244, 1) 0%,rgba(0, 160, 233, 1) 100%);
  background : -webkit-linear-gradient(45deg, rgba(126, 206, 244, 1) 0%, rgba(0, 160, 233, 1) 100%);
  background : -webkit-gradient(linear,20% 200% ,80% -100% ,color-stop(0,rgba(126, 206, 244, 1) ),color-stop(1,rgba(0, 160, 233, 1) ));
  background : -o-linear-gradient(45deg, rgba(126, 206, 244, 1) 0%, rgba(0, 160, 233, 1) 100%);
  background : -ms-linear-gradient(45deg, rgba(126, 206, 244, 1) 0%, rgba(0, 160, 233, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#7ECEF4', endColorstr='#00A0E9' ,GradientType=0)";
  background : linear-gradient(45deg, rgba(126, 206, 244, 1) 0%, rgba(0, 160, 233, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7ECEF4',endColorstr='#00A0E9' , GradientType=1);
}

@mixin gd-1{
  background : -moz-linear-gradient(0% 50% 0deg,rgba(234, 246, 253, 1) 0%,rgba(186, 227, 249, 1) 100%);
  background : -webkit-linear-gradient(0deg, rgba(234, 246, 253, 1) 0%, rgba(186, 227, 249, 1) 100%);
  background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(234, 246, 253, 1) ),color-stop(1,rgba(186, 227, 249, 1) ));
  background : -o-linear-gradient(0deg, rgba(234, 246, 253, 1) 0%, rgba(186, 227, 249, 1) 100%);
  background : -ms-linear-gradient(0deg, rgba(234, 246, 253, 1) 0%, rgba(186, 227, 249, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#EAF6FD', endColorstr='#BAE3F9' ,GradientType=0)";
  background : linear-gradient(90deg, rgba(234, 246, 253, 1) 0%, rgba(186, 227, 249, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EAF6FD',endColorstr='#BAE3F9' , GradientType=1);
}

@mixin gd-2
{
  background : -moz-linear-gradient(100% 50% -180deg,rgba(234, 246, 253, 1) 0%,rgba(186, 227, 249, 1) 100%);
  background : -webkit-linear-gradient(-180deg, rgba(234, 246, 253, 1) 0%, rgba(186, 227, 249, 1) 100%);
  background : -webkit-gradient(linear,100% 50% ,0% 50% ,color-stop(0,rgba(234, 246, 253, 1) ),color-stop(1,rgba(186, 227, 249, 1) ));
  background : -o-linear-gradient(-180deg, rgba(234, 246, 253, 1) 0%, rgba(186, 227, 249, 1) 100%);
  background : -ms-linear-gradient(-180deg, rgba(234, 246, 253, 1) 0%, rgba(186, 227, 249, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#EAF6FD', endColorstr='#BAE3F9' ,GradientType=0)";
  background : linear-gradient(270deg, rgba(234, 246, 253, 1) 0%, rgba(186, 227, 249, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EAF6FD',endColorstr='#BAE3F9' , GradientType=1);
}

body.showBlk *,
body.showBlk *:after,
body.showBlk *:before {
	background: rgba(48, 210, 222, 0.13);
}

@mixin anchor($color:#000,$size:8px,$Bw:1px,$left:auto,$right:10px){
	display: block;
	position: absolute;
	top: 50%;left:$left;right:$right;
	content: "";
	width: $size;height: $size;
	border: 1px solid  $color;
	border-width: 0 $Bw $Bw 0;
	@include transform(rotate(-45deg) translateY(-50%));
	transform-origin: center center;
}


@mixin flex-centering{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}



@mixin fitW($n:30){
	margin-left: -$n*$px;
	width: 100vw;
}

@mixin fitW2(){
	margin-left: -20*$px;
	width: 100vw;
}


@mixin pfix($gap){
	$em:$gap/2;
	margin-top: -$em;
	margin-bottom: -$em;
}


@mixin text-line($color:#000,$width:1px,$blur:0px){
	text-shadow: $width $width $blur $color,-$width -$width $blur $color,$width -$width $blur $color,-$width $width $blur $color,0px $width $blur $color,0px -$width $blur $color,$width 0px $blur $color,-$width 0px $blur $color;
}


@mixin transform($arg){
	-webkit-transform:$arg;
	-moz-transform:$arg;
	-ms-transform:$arg;
	-o-transform:$arg;
	transform:$arg;
}

@mixin tap($scale:0.9){
	transition: 200ms $ease1;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	a{
		-webkit-tap-highlight-color:rgba(0,0,0,0);
	}
	&:active{
		@include transform(scale($scale));
		will-change:transform;
	}
}


@import url('https://fonts.googleapis.com/css?family=Barlow:400,500,600');

ul,li,dl,dd,dt,ol{
	padding: 0;margin: 0;
	list-style: none;
}

img{
	backface-visibility: hidden;
}

a{
	text-decoration: none;
}

*{
	box-sizing: border-box;
	::after,::before{
		box-sizing: border-box;
		
	}
}

.idx{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
@mixin hover1{
	position: relative;
	&:hover{
		box-shadow:0px 2px 4px rgba(0, 0, 0, 0.5) ;
		top: -2px;
	}
	&:active{
		top: 0;
		box-shadow:0px 2px 4px rgba(0, 0, 0, 0) ;
	}
}

@mixin innerRect($size){
	position: absolute;
	top: $size;left:$size;
	width: calc(100% - (#{$size * 2}) );
	height: calc(100% - (#{$size * 2}) );
	z-index: 0;
}

@keyframes rotation {
  0% {
    @include transform(translate(-50%,-50%) rotate(0));
  }

  100% {
     @include transform(translate(-50%,-50%) rotate(359deg));
  }
}


@keyframes swing {
  0% {
    @include transform(rotate(-5deg));
  }

  100% {
     @include transform(rotate(5deg));
  }
}


@mixin bg-img($url,$size:100% 100%,$position:center center,$repeat:no-repeat){
	background-image: $url;
	background-repeat: $repeat;
	-webkit-background-size:$size ;
	background-size:$size ;
	background-position:$position;
}

@import url('https://fonts.googleapis.com/css?family=Ropa+Sans');

@mixin num{
	font-family: 'Ropa Sans', sans-serif;
}

@mixin minchou{
	//font-family: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
	font-family: "游明朝", "YuMincho Medium", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif !important;
}

@mixin gothic{
	//font-family:  Hiragino Sans, "ヒラギノ角ゴシック","メイリオ", "ＭＳ Ｐゴシック","Hiragino Kaku Gothic ProN","YuGothic",Meiryo,"メイリオ",sans-serif !important;
	font-family:"游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium","游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", Helvetica, Arial,sans-serif !important;
}

@mixin note($pl:1.4em){
	padding-left: $pl;
	position: relative;
	i{
		font-style: normal;
		position: absolute;
		top: 0;left: 0;
	}
}

@mixin transition($arg){
	-webkit-transition:$arg;
	-moz-transition:$arg;
	-ms-transition:$arg;
	-o-transition:$arg;
	//filter:$arg;
}


@mixin filter($arg){
	-webkit-filter:$arg;
	-moz-filter:$arg;
	-ms-filter:$arg;
	-o-filter:$arg;
	filter:$arg;
}


@mixin brend($color){
	background-blend-mode: exclusion;
	background-color: $color ;
}

@mixin webfont($fw){
		//font-family: 'Ropa Sans', sans-serif;
		font-family: 'Roboto Slab', serif !important;
		font-weight: $fw;
	}

@mixin header-icon-shadow(){
	-webkit-filter:drop-shadow(rgba(0,0,0,0.8) 1px 1px 2px);
}

@mixin a-cover{
	position: absolute;
	display: block;
	z-index: 1;
	top: 0;
	left: 0;
	width:100%;
	height:100%;
}

@mixin centering{
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform(translate(-50%, -50%));
}

@mixin side-kakko{
	position: relative;
	&:before{
		content: "";
		position: absolute;
		top: 0;left: 0;
		border: 1px solid #666;
		border-right-width: 0;
	}
	&:before{
		content: "";
		position: absolute;
		top: 0;left: 0;
		border: 1px solid #666;
		border-right-width: 0;
		height: 100%;
		width: 15px;
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;right: 0;
		border: 1px solid #666;
		border-left-width: 0;
		height: 100%;
		width: 15px;
	}
}

@mixin bar($color){
	content: "";
	width: 50px;
	height: 5px;
	background:$color ;
	display:block;
	margin: 0 auto;
}


@mixin anchor($color:#000,$size:8px){
	display: block;
	position: absolute;
	right: 15px;top: 50%;
	content: "";
	width: $size;height: $size;
	border: 1px solid  $color;
	border-width: 0 1px 1px 0;
	@include transform(rotate(-45deg) translateY(-50%));
	transform-origin: center center;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  } 
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

@include keyframes(hover){
    
    30%{
	    //transform-origin: bottom;
	    @include transform(translate3d(0,-12px,20px) scale(1) /* rotateY(-3deg) */ rotateX(-5deg));
    }
    
    100%{
	    //transform-origin: bottom;
	    @include transform(translate3d(0,-10px,15px) scale(1) /* rotateY(-3deg) */ rotateX(-2deg));
    }
}

@include keyframes(out){
    
    
    0%{
	    //transform-origin: bottom;
	    @include transform(translate3d(0,-10px,15px) scale(1) /* rotateY(-3deg) */ rotateX(-2deg));
    }
}

