.pc {
  display: block;
}

.sp {
  display: none !important;
}

#responsive_flg {
  display: none;
}

/* ------------------------------------------------------------*/
/* sp 〜767
------------------------------------------------------------*/

@media screen and (max-width: 767px) {


	
.pc {display: none !important;}
.sp {display: block !important;}

/* !!------------------------------------ */
/* !! 共通css */
html{
	font-size: 62.5*.8%;

}

body{
	margin: 0;
	padding-top: 50px;
	color: $text;
	background: #fff;
	@include gothic;
	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

div#shade{
	//position: fixed;
	width: 100% !important;
	height: 100% !important;
	background: #fff;
	top: 0;left: 0;
	z-index: 1000;
}

div#view{
	height: 100vh;
}

#container{
	//display: none;
	min-width:0px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	
}

.inner {
	width: auto;
	height: 100%;
	margin: 0 auto;
	position: relative;
	
}

img{
	backface-visibility:hidden;
	-webkit-backface-visibility: hidden;
}

.notf{
	@include transform(none !important);
}

img{
	max-width: 100%;
	height: auto;
}

a.na{
	opacity: .5;
	pointer-events: none;
}

.btn{
	width: 250px;
	height: 40px;
	border-radius: 20px;
	@include gd-GR;
	display: block;
	line-height: 40px;
	text-align: center;
	color: #fff;
	font-weight: 600;
	font-size: 1.6rem;
	position: relative;
	
	&:after{
		@include anchor($color:#fff);
	}
	&.cart{
		@include gd-OR;
		&:after{content:none;}
		&:before{
			content: "";
			display: inline-block;
			width: 21px;
			height: 20px;
			vertical-align: middle;
			margin-right: 10px;
			position: relative;
			top: -3px;
			@include bg-img(url(../img/icon-cart.png)); 
		}
	}
}

/* !!------------------------------------ */
/* !! header */


#site-header{
	
	background: #fff;
	height: 50px;
//	position: fixed;
//	top: 0;left: 0;
//	z-index: 10;
//	width: 100%;
	min-width: 0;
	.h1txt{
		position: absolute;
		left:10px;top: 50%;
		height: 30px !important;
		img{
			width: auto !important;
			height: 100% !important;
		}
/*
		@include transform(translateY(-50%));
		&.top{
			display: none;
		}
*/
	}
	
	body.top & .h1txt{
		display: none;
		&.top{
			display: block;
		}
	}
	
	a.btn{
		width: 220px;
		position: fixed;
		top: auto;bottom: 15px;left: 50%;
		@include transform(translate(-50%,0));
		right: 50px;
		font-weight: bold;
	}

}


#gnav ul.gnav{
	position: fixed;
	background: #fff;
	right: 0;
	top: 50px;
	width: 100%;
	height: 100%;
	padding-top: 20px;
	@include transform(translateY(0));
	opacity: 0;
	pointer-events: none;
	transition: 500ms;
	body.menuOn & {
		opacity: 1;
		pointer-events: all;
	}
	li{
		display: block;
		border-bottom: 1px solid $line;
		margin:0 20px ;
		a{
			line-height: 50px;
			display: block;
			font-weight: bold;
			font-size: 1.6rem;
			padding: 0;
			position: relative;
			&:after{
				@include anchor();
			}
		}
	}
}

#navBtn{
	background: #eee;
	width: 50px;
	height: 50px;
	position: absolute;
	top: 0px;right: 0px;
	z-index: 1;
	font-size: 1.3rem !important;
	cursor: pointer;
	i{
		position: absolute;
		background: #858585;
		height: 4px;
		width: 30px;
		border-radius: 3px;
		position: absolute;
		top: 23px;
		left: 10px;
		transition: 300ms $ease1;
		&._1{
			@include transform(translateY(-5px) rotate(0));
		}
		&._2{
			@include transform(translateY(5px) rotate(0));
		}
	}
	
	body.menuOn & {
		i._1	{
			@include transform(translateY(0px) rotate(-45deg));
		}
		i._2	{
			@include transform(translateY(0px) rotate(45deg));
		}
	}
}



/* !!------------------------------------ */
/* !! トップページ */

.mv{
	//width: 100%;
	height: calc(80vw);
	//position: relative;
	p{
		position: absolute;
		top: 50%;
		left: 50%;
		height: auto;
		z-index: 1;
		@include transform(translate(-50%,-50%));
		img{
			display: block;
			width: 80vw;
		}
	}
/*
	&.inv{
		.img{
			@include filter(blur(4px));
			transform: scale(1.05);
		}
	}
	.img{
		width:100%;
		height:100%;
		transition: 5000ms;
		@include filter(blur(0px));
		transform: scale(1);
		@include bg-img(url(../../img/top/mv.jpg),cover); //$url,$size,$position,$repeat
	}
*/
	
/*
	a.scroll{
		
		position: absolute;
		z-index: 1;
		bottom: 0;left: 50%;
		height: 53px;
		@include transform(translateX(-50%));
		img{
			height: auto;
			display: block;
		}
	}
*/
	
}//.mv


h2{
	font-size: 2.4rem;
	font-weight: normal;
	letter-spacing: 0;
}

.top main{
	background: #fff;
	display: block;
	position: relative;
	z-index: 3;
	min-width: 0;
	overflow: hidden;
	>section{
		display: block;
		padding: 50*$px 50*$px;
		.inner{
			max-width: 1200px;
			height: 100%;
			margin: 0 auto;
		}
		
		h2{
			@include minchou;
			font-size: 2.4rem;
			font-weight: normal;
			letter-spacing: 0;
			position: relative;
			padding-left: 50*$px;
			@include fitW(50);
			&:after{
				content: "";
				width: 40*$px;
				height: 1px;
				display: block;
				background: $text;
				position: absolute;
				left: 0;top: 10*$px;
			}
			span{
				font-size: 0.58em;
				display: block;
			}
		}
	}
}



/* !! - - -  */
/* !! エーエルジャパン製品案内 */

//!! - スライド
#slide{
	margin-top: 50px;
	text-align: center;
	font-size: 0;
	white-space: nowrap;
	.cell{
		*:focus{
			outline: none;
		}
		display: inline-block;
		background: #F5F6F6;
		width: 200px;height: 200px;
		margin: 0 10px;
		position: relative;
		a{
			width:100%;
			height:100%;
			display: block;
		}
		img{
			height: 150px !important;
			width: auto !important;
			display: block;
			@include centering;
		}
	}
	button{
		cursor: pointer;
	}
	.slick-prev{
		background-color: transparent;
		width: 18px;
		height: 32px;
		font-size: 0;
		border: none;
		position: absolute;
		left: -36*$px;
		top: 50%;
		@include transform(translateY(-50%));
		@include bg-img(url(../img/anchor-l.png)); 
	}
	.slick-next{
		background-color: transparent;
		width: 18px;
		height: 32px;
		font-size: 0;
		border: none;
		position: absolute;
		right: -36*$px;
		top: 50%;
		@include transform(translateY(-50%));
		@include bg-img(url(../img/anchor-r.png)); 
	}
}


/* !! - - -  */
/* !! 安心安全への取り組み */
#forsafety{
	margin-bottom: 50*$px;
	position: relative;
	.inner{
		padding-left: 0;
		width: 100%;
	}
	@include gd-1;
	.img{
		position: absolute;
		bottom: 0;left: 0;
		z-index: 0;
		width: 100%;
		opacity: .3;
		img{
			width: 100% !important;
			height: auto !important;
		}
		pointer-events: none;
	}
	h2{
		position: relative;
		margin-left: 0;
		@include fitW(50);
		position: relative;
		z-index: 1;
	}
	p{
		margin: 40px 0;
		margin-left:0px;
		position: relative;
		z-index: 1;
	}
	.btn{
		margin-left: 0px;
		margin: 0 auto;
		position: relative;
		z-index: 1;
		width: 100%;
	}
}




/* !! - - -  */
/* !! 定期注文をおすすめする理由 */
section#outline{
	position: relative;
	@include gd-2;
	
	.img{
		position: absolute;
		bottom: 0;right: 0;z-index: 0;
	
		img{
			width: 400*$px;
			opacity: .3;
			
			height: auto !important;
		}
	}
	
	strong{
		font-size: 2rem;
		line-height: 1.7;
		@include minchou;
		display: block;
		margin: 25px 0 20px 0;
	}
	p{
		margin-left: 0;
		position: relative;
		z-index: 1;
		@include pfix(0.88em);
		br{
			display: none;
		}
	}
	
}




/* !! - - -  */
/* !! お知らせ */
ul.topics-list{
	margin-left: 0;
	margin-right: 0;
	li{
		&:after{
			@include anchor();
			right: -20*$px;
			outline-color: #000;
		}
		position: relative;
		overflow: hidden;
		padding: 20px 0 ;
		font-size: 1.4rem;
		border-bottom: 1px solid $line;
		>div{
			display: inline-block;
			vertical-align: top;
			float: left;
		}
		.date{
			line-height: 20px;
			float: left;
			clear: both;
		}
		.cate{
			background: #FCD475;
			width: 100px;
			height: 20px;
			line-height: 20px;
			float: right;

			text-align: center;
			margin: 0px;
		}
		.title{
			display: block;
			width: 100%;
			clear: both;
			margin-top: 10px;
		}
	}
}





/* !! - - -  */
/* !! フッター */

#page-footer{
	padding-bottom: 30*$px;
	.wrap{
		background: #EFEFEF;
		text-align: center;
		padding: 30*$px ;
		a{
			vertical-align: top;
			
		}
		.tel{
			width: 100%;
			display: inline-block;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		.btn.contact{
			margin-left: 0px;
			display: inline-block;
			@include gd-BLU;
			border-radius: 0;
			width: 100%;
			height: 50px;
			margin-top: 11px;
			line-height: 50px;
			font-weight: bold;
		}
	}
	#fnav{
		text-align: center;
		padding:0 30*$px;
		ul{
			position: static;
			@include transform(translateY(0));
			li{
				display: block;
				line-height: 1;
				padding: 15px 0;
				border-bottom: 1px solid $line;
				font-size: 1.6rem;
				&:last-of-type{
					border-bottom: 0;
				}
				a{
					line-height: 1;
					padding: 0 15px;
				}
			}
		}
	}
	p.copy{
		text-align: center;
		line-height: 50px;
		font-size: 1.2rem;
		border-top: 1px solid $line;
		margin-bottom: 40px;
	}
}



/* !!------------------------------------ */
/* !! 2階層以降共通 */

.path{
	//max-width: 1200px;
	//margin: 0 auto;
	//font-size: 0;
	padding: 10px;
	width: 100%;
	border-bottom: 1px solid #eee;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	li{
		font-size: 1.4rem !important;
		display: inline-block;
		line-height: 1;
		margin-top:0px;
		&:after{
			content: " ＞ ";
		}
		&:last-of-type:after{
			content: none;
		}
		a:hover{
			text-decoration: underline;
		}
	}
}

main.page{
	
	.page-title{
		margin-top: 30*$px;
		text-align: center;
		@include minchou;
		font-size: 2.4rem;
		font-weight: 500;
		letter-spacing: .1em;
		position: relative;
		span{
			font-size: 0.58em;
			display: block;
		}
		& + .lead{
			padding-top: 20*$px;
			text-align: center;
			@include pfix(.8em);
		}
	}
	
	
}//main.page



/* !!------------------------------------ */
/* !! エーエルジャパン製品の案内 */

main.product{
	
	.mv{
		margin-top: 50px;
		height: 200px;
		padding: 0;
		overflow: hidden;
		@include bg-img(url(../../product/img/mv-bg.jpg),cover); //$url,$size,$position,$repeat
		p{
			width: 325.08*$px;
			height: 60.48*$px;
			//position: absolute;
			//top: 50%;
			//left: 50%;
			@include transform(translate(-50%,-50%));
			img{
				position: absolute;
				width: 100% !important;
				height: auto !important;
				top: 0;left: 0;
			}
		}
	}
	
	>section{
		//background: #eee;
		padding: 0;
		margin: 50px 0;
		font-size: 0;
		//text-align: right;
		background-size: auto 200px !important;
		&:last-of-type{
			margin-bottom: 10px;
		}
		.inner{
			padding: 50px 30px;
		}

		.item{
			height: auto;
			display: block;
			text-align: right;
			position: relative;
			width: 100%;
			.img{
				float: none;
				margin-left: 0px;
				text-align: center;
				img{
					height: 240*$px !important;
					width: auto !important;
				}
			}
			.txt{
				//background: #fff;
				display: block;
				height: 100%;
				font-size: 0;
				position: relative;
				p.catch{
					font-size: 2.2rem;
					line-height: 1.75em;
					text-align: justify ;
					//@include minchou;
					white-space: normal;
					//@include pfix(.75em);
					margin-top: 15px;
				}
				.data{
					position: relative;
					bottom: 0;right: 0;
					p.name{
						//font-size: 1.8rem;
						//font-weight: 600;
						margin-top: 15px;
						text-align: justify;
						width: auto;
						padding-bottom: 8px;
						border-bottom: 1px solid $line;
					}
					p.desc{
						text-align: justify;
						br{
							display: none;
						}
						padding: 15px 0 30px 0;
						@include pfix(.8em);
					}
					.btn{
						display: block;
						margin: 0 auto;
					}
						
				}
			}
			
		}
		
		&.left{
			text-align: left;
			.item{
				text-align: left !important;
			}
			.img{
				float: none;
				margin: 0;
				margin-right: 0px;
			}
			p.catch{
				text-align: left !important;
			}

			.data{
				bottom: 0;right:auto;
				left: 0;
				p.name{
					text-align: left !important;
				}
				p.desc{
					text-align: left !important;
				}
				.btn{
					display: inline-block;
				}
					
			}
		}
		
		.catch.outer{
			//@include minchou;
			line-height: 1.5;
			font-size: 2.2rem;
			//text-align: center;
		}
		
		.idx{
			margin-top: 50px;
			display: block;
			>div.left{
				width: 100%;
			}
			>div.right{
				width: 100%;
				margin-top: 50px;
			}
		}
		
		
		
	}//>section
	
	.premium-croire{
		background-position: left top !important;
	}
	
	.croire-eye-pro{
		.inner{padding-bottom: 50px !important;}
		background-position: left top !important;
	}
	
	.DHA-EPA{
		background-position: right top !important;
	}
	
	.NA{
		background-position: left top !important;
	}
	
	.Q10{
		background-position: right top !important;
	}

	.croire-gold{
		background-position: left top !important;
	}
	
	.wahaha-haburashi{
		background-position: right top !important;
	}	
	
}//main.product

/* !!------------------------------------ */
/* !! ★商品単体ページ共通 */

ul.path.product{
	top: 0;
	position: relative;
	z-index: 2;
	height: 50px;
	border-bottom: 0;
}


main.single{	
	
	margin-top: -50px;
	position: relative;
	z-index: 1;
	padding: 0 !important;
	.mv{
		background: #fff;
		height: auto;
		//overflow: hidden;
		//-webkit-background-size: cover;
		background-size: auto 200px !important;
		background-position: left top !important;
		.inner{
			//background: #ddd;
			position: relative;
			height: auto;
			max-width: 1200px;
			height: auto;
			margin: 0 !important;
			margin-top: 50*$px !important;
			.img{
				float: none;
				height: auto;
				text-align: center;
				margin: 0;
				margin-bottom: 20px;
				img{
					width: auto !important;
					height: 260px !important;
				}
			}
		}
		
		.data{
			height: auto;
			width: auto;
			float: none;
			margin-right: 0px;
			position: relative;
		}
		
		h1{
			text-align: center;
			margin: 0 30*$px;
			@include minchou;
			font-size: 2.4rem;
			font-weight: normal;
			@include pfix(.71em);
			line-height: 1.71;
			letter-spacing: .1em;
			position: relative;
			top: 0;
		}
		.logo{
			position: static;
			bottom: 0;right: 0;
			margin: 20px auto;
			display: block;
		}
	}
	
	
	.lead{
		padding: 50*$px 30*$px;
		text-align: center;
		h2{
			line-height: 1.5;
			padding-bottom: 30px;
		}
		p{
			text-align: justify !important;
			@include pfix(.8em);
		}
	}
	
	
	section.about{
		overflow: hidden;
		background: #EAF6FD;
		.inner{
			padding: 0;
			max-width: 1000px;
			margin: 50*$px 30*$px !important;
			
			h2.Q{
				line-height: 1;
				text-align: left;
				border-bottom: 1px solid $line;
				padding-bottom: 20px;
				margin-bottom: 0 !important;
			}
			p.A{
				padding-top: 20px;
				text-align: justify;
				@include pfix(.75em);
			}
		}		
		
	}//section.about
	
	
	>section{
		.inner{
			max-width: 1000px;
			margin: 0 auto;
			padding:50*$px 30*$px;
			h2{
				line-height: 1.75;
				text-align: center;
				@include pfix(.75em);
				margin: 50*$px 0 50*$px 0;
				&:first-of-type{
					margin-top: 0;
				}
			}
		}
	}
	
	h3.border{
		max-width: 200px;
		line-height: 32px;
		border: 1px solid $text;
		text-align: center;
		border-radius: 20px;
		font-size: 1.8rem;
		letter-spacing: .1em;
		font-weight: bold;
		margin: 30*$px auto;
	}
	
	
	//!! - イメージリスト

	ul.img-list{	
		font-size: 0;
		text-align: center;
		//background: #eee;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&._50{
			li{
				margin: 0 25px;
			}
		}
		li{
			width: calc((100% - 30px) / 3);
			margin: 0;
			margin-bottom: 20*$px !important;
			display: inline-block;
			.img{
				width: 100%;
				height: auto;
				background: #eee;
				border-radius: 50%;
				img{
					width: 100% !important;
					height: auto !important;
				}
			}
			span{
				text-align: center;
				display: block;
				margin-top: 10px;
				font-size: 1.6rem;
				//background: #eee;
				width: 200%;
				left:50%;
				letter-spacing: .05em;
				@include transform(translate(-50%,0));
				position: relative;
				b{
					display: block;
					font-size: 1.13em;
					i{
						font-size: 1.22em;
					}
				}
			}
		}
		
		//!! - - 中サイズ
		&.mid{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			
			li{
				width: calc(50% - 10px ) !important;
				//vertical-align: top;
				&:nth-last-child(-n+2){
					margin-bottom: 0;
				}
				.img{
					height: auto;
				}
				span{
					width: 100% !important;
					left: 0;
					@include transform(translate(0));
					text-align: justify;
				}
			}
		}
		
		//!! - - 大サイズ
		&.lg{
			li{
				width: calc(50% - 10px);
				//vertical-align: top;
				&:nth-last-child(-n+2){
					margin-bottom: 0;
				}
				.img{
					height: auto;
				}
				span{
					padding-top: 20px;
					margin-top: 20px;
					font-size: 1.6rem;
					@include pfix(.7em);
				}
			}
		}
		
	}//ul.img-list
	
	
	ul.img-list2{
		
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		li{
			width: calc((100% - 20px)/2);
			margin-bottom: 20px;
			.img{
				background: #fff;
				height: auto;
				position: relative;
				img{
					width: 100% !important;
					height: auto !important;
					display: block;
				}
				i{
					position: absolute;
					bottom: 0;left: 0;
					line-height: 22px;
					background: #FACD00;
					width: 100%;
					text-align: center;
					font-size: 1.6rem;
					z-index: 1;
				}
			}
			p{
				font-size: 1.5rem;
				b{
					display: block;
					font-size: 1.13em;
					line-height: 1;
					margin: 20px 0 10px 0;
				}
			}
			
		}
	}
	
	section.bg-GR{
		background: #EFEFEF;
		overflow: hidden;
		.inner{
			max-width: 1000px;
			margin: 0 auto;
			h2{
				br{
					display: none;
				}
			}
		}
	}
	
	
	.note{
		font-size: 1.4rem;
	}
	
	p.hd{
		line-height: 36px;
		text-align: center;
		font-size: 2rem;
		font-weight: 600;
		letter-spacing: .1em;
		border-radius: 25px;
		&.box{
			border-radius: 0;
		}
		&.border{
			border: 1px solid $text;
		}
	}
	
	section .inner > div.box{
		//background: #eee;
		padding: 50px 0;
		border-bottom: 1px dotted #333;
	}

	div.img-left{
		position: relative;
		.img{
			width: 60%;
			height: auto;
			margin-left: 0;
			margin: 0 auto;
			img{
				width: 100% !important;
				height: auto !important;
				display: block;
			}
		}
		&.block{
			.img{
				.caption{
					font-size: 1.8em;
					text-align: left;
				}
				width: 100%;
				height: auto;
				margin-left: 0;
			}
			.txt{
				left: 0;
				width: 100%;
				padding-left:0;
				margin-top: 15px;
				text-align: justify;
			}
		}
		.txt{
			//background: #eee;
			position:static;
			text-align: left;
			margin-top: 20*$px;
			//left: 0;
			//top: 50%;
			@include transform(translateY(0));
			h2{
				text-align: left;
				margin: 0;
				line-height: 1;
				margin-bottom: 20px;
			}
			p{
				text-align: left;
				@include pfix(.7em);
			}
		}
	}
	
	div.img-right{
		position: relative;
		.img{
			padding-left: 0;
			text-align: center;
			img{
				max-width: 70%;
				height: auto;
			}
		}
		.txt{
			//background: #eee;
			position:static;
			text-align: justify;
			left: 0;
			width: 100%;
			margin-top: 20*$px;
			@include transform(translateY(0));
			h3{
				text-align: left;
				margin: 0;
				line-height: 1;
				font-size: 2.4rem;
				letter-spacing: .1em;
				@include minchou;
				font-weight: normal;
				margin-bottom: 20*$px;
			}
			p{
				text-align: left;
				@include pfix(.7em);
			}
		}
	}
	
	table.nutrient{
		table-layout: fixed;
		width: 100% !important;
		height: auto !important;
		border-collapse: collapse;
		position: relative;
		caption{
			font-size: 1.6em;
			letter-spacing: .1em;
			position: absolute;
			top: -2em;
			right: 0;
		}
		th,td{
			border: 1px solid $line;
			padding: 10px;
			font-size: 1.6rem !important;
		}
		th{
			background: #EAF6FD;
			width: 200px;
		}
		td{
			font-size: 1.4rem;
			text-align: right;
		}
		& + table.material{
			margin-top: 50px;
		}
	}
	
	table.material{
		margin-top: 50*$px;
		width: 100%;
		border-collapse: collapse;
		th,td{
			border: 1px solid $line;
			padding: 20px;
		}
		th{
			background: #EAF6FD;
			font-size: 2rem;
			width: auto;
		}
		td{
			font-size: 1.4rem;
		}
	}
	
}


/* !!------------------------------------ */
/* !! ★プレミアムクロワール茶 */


main.premium-croire{
	
	.mv{
		@include bg-img(url(../../product/img/bg01.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	.img.sod{
		text-align: center;
	}
	
	.desc{
		text-align: center;
		margin-top:30*$px;
		text-align: justify;
	}
	
	.result{
		position: relative;
		padding-right: 0;
		.img{
			position: static;
			width: 50%;
			margin: 0 auto;
			margin-top: 20px;
			top: 0;right: 0;
			border: 1px solid $line;
		}
		.note{
			margin-top: 10px;
		}
		
		h3{
			text-align: center;
		}
		.red{
			color: #B81C22;
			font-size: 2.6rem;
			position: relative;
			padding-right: 0;
			font-weight: bold;
			line-height: 1.4;
			margin-bottom: 20px;
			br{
				display: none;
			}
			img{
				height: auto;
				display: block;
				margin-top: 15px;
				width: auto;
				position: static;
				top: 0;
				right: 0;
			}
		}
	}
	
	.idx{
		display: block;
		//justify-content: space-between;
	}
	
	
}//main.croire


/* !!------------------------------------ */
/* !! ★クロワールアイ・プロ */
main.croire-eye-pro{
	
	.mv{
		@include bg-img(url(../../product/img/bg02.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	.idx{
		display: block;
		//flex-wrap: wrap;
		//justify-content: space-between;
		&.rev{
			flex-direction: row-reverse;
		}
	}
	
	.graf{
		
		width: 100%;
		margin: 0 auto;
		margin-top: 0px;
		
		.txt{
			width: 100%;
			height: auto;
			margin-bottom: 20*$px;
			p{
				position: relative;
				top: 50%;
				margin-top: 0px;
				@include transform(translateY(0));
			}
		}
	}
	
	.img.ac{
		text-align: center;
	}
	
	p.ac{text-align: justify;}
	
	
	
	h2.bd + p{
		padding-top: 30px;
		@include pfix(.7em);
	}
	
	img.KEMIN{
		vertical-align: middle;
		position: relative;
		top: -2px;
		margin-right:10px;
		//top: 50%;
		//display: inline-block;
	}
	
	.mt30{
		margin-top: 30px;
	}
	
	.idx.box{
		display: block;
		dl{
			max-width: 475px;
			//width: 100%;
			margin: 0;
			margin-top: 30*$px;
			dt{
				position: relative;
				text-align: center;
				padding-top: 20px;
				span{
					position: absolute;
					top: 0;left: 0;
					font-weight: bold;
					font-size: 2.2em;
				}
			}
			dd{
				font-size: 1.6rem;
				padding-top: 20px;
				@include pfix(.7em);
				
			}
		}
	}
	
	ul.img-list._200{
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		li{
			width: calc(50% - 10px) !important;
			margin: 0;
			//vertical-align: top;
			margin-bottom: 30*$px !important;
			b{
				font-size: 2rem;
				font-weight: bold;
				line-height: 1;
				display: block;
				margin-bottom: 10px;
			}
			.img{
				height: auto;
			}
		}
	}
	
	ul.more-elem{
		font-size: 0;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		
		li{
			display: block;

			width: calc((100% - 20px) / 3);
			//letter-spacing: .1em;
			margin:0 ;
			margin-bottom: 10px;
			padding: 0;
			line-height: 50px;
			//padding: 0 20px;
			min-width: 0px;
			///text-align: center;
			//font-size: 2rem;
			//font-weight: bold;
			&.col2{
				width: 100%;
			}
		}
	}
	
	table.nutrient{
		//table-layout: fixed;
		//width: 100% !important;
		//height: auto !important;
		//border-collapse: collapse;
		//position: relative;
		caption{
			font-size: 1.6em;
			letter-spacing: .1em;
			position: absolute;
			top: -2em;
			right: 0;
		}
		th,td{
			border: 1px solid $line;
			padding: 12px;
			font-size: 1.2rem !important;
		}
		th{
			background: #EAF6FD;
			width: 200px;
		}
		td{
			font-size: 1.2rem;
			text-align: right;
		}
		& + table.material{
			margin-top: 50px;
		}
	}
	
	
}//main.croire-eye-pro



/* !!------------------------------------ */
/* !! ★クロワールアイゴールド */
main.croire-gold{
	
	.mv{
		@include bg-img(url(../../product/img/bg06.jpg)); 
		//$url,$size,$position,$repeat
	}

}


/* !!------------------------------------ */
/* !! ★Nアセチルグルコサミンを */
main.NA{
	
	.mv{
		@include bg-img(url(../../product/img/bg04.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	.lead{
		margin: 0 0*$px;
		padding: 0;
		display: block;
		margin-top: 40px;
		.inner{
			@include flex-centering;
			padding:  0 !important;
			margin: 0 20*$px;
			padding-bottom: 20*$px !important;
			border-bottom:1px solid $line;
		}
		.img{
			height: auto;
			//background: #eee;
			@include flex-centering;
			margin: 20px 0;
			margin-bottom: 0;
			&.arr{
				width: 105*$px;
			}
			._1{
				position: relative;
				top: 0px;
				width: 220*$px;
			}
		}
		.box{
			padding: 20px 0 !important;
			border: 2px solid #000 !important;
			border-radius: 10px;
			height: 160px;
			//background: #EEE;
			display: block;
			height: auto;
			padding: 20px;
			margin: 0 20*$px;
			h2{
				padding:0;
				padding-bottom: 20px;
				margin: 0 20px !important;
				text-align: center;
				@include gothic;
				font-weight: bold;
				width: auto;
				font-size: 18px;
				border: 0;
				border-bottom: 1px solid #000;
				br{
					display: none;
				}
			}
			ul{
				text-align: left;
				margin-top: 15px;
				font-size: 14px;
				padding: 0 20px;
				li{
					line-height: 24px;
					@include note();
					i{
						color: $kon;
					}
				}
			}
		}
	}
	
	div.idx{
		display: flex;
		justify-content: space-between;
	}
	
	section._1{
		.inner{
			padding: 0 40*$px;
			padding-top: 20*$px;
		}
		h3{
			font-size: 18*$px;
			font-weight: 500 !important;
			color: $kon;
			margin-bottom: 20px;
			white-space: nowrap;
			strong{
				font-weight:700 !important;
				letter-spacing: .05em;
			}
			img{
				margin-right: 10px;
				width: 104*$px;
			}
			i{
				display: inline-block;
				padding-bottom: 3px;
				@include bg-img(url(../../product/NA/img/wave.png),14px 5px,bottom left,repeat-x); //$url,$size,$position,$repeat
			}
		}
		
		.img._1{
			width: 177*$px;
			margin: 0 auto;
		}
		
		.txt{
			width: 100%;
			padding-left: 0px;
			@include flex-centering;
			position: relative;
			>img{
				position: relative;
				left: 0;
				margin: 20px auto;
				top: 0;
				@include transform(translate(-0,0));
			}
			.hd{
				text-align: center;
				width: 100%;
				p{
					text-align: center;
					font-size: 18px;
					padding: 15px 0;
					line-height: 1.56;
					position: .56em;
				}
				border-bottom: 1px solid $line;
			}
			h4{
				text-align: center;
				font-size: 24px;
				@include minchou;
				line-height: 1;
				
			}
			>p{
				padding-top: 15px;
				font-size: 14px;
				line-height: 28px;
				text-align: justify;
				@include pfix(.45em);
			}
		}
	}
}


/* !! - 成分 */
section.seibun{
	h2{
		text-align: center;
		color: $kon;
		font-size: 20px;
		line-height: 1;
		max-width: 1200px;
		margin: 30*$px 0;
		white-space: nowrap;
		letter-spacing: .1em;
		&:after{
			content: none;
		}
		i{
			background: #fff;
			padding: 0 30px;
			@include gothic;
			font-weight: bold !important;
			position: relative;
			z-index: 1;
		}
	}
	
	.seibun{
		margin: 0 40*$px;
		border-radius: 10px;	
		border: 3px solid $kon;
		margin-top: 36*$px;
		max-width: 974px;
		position: relative;
		left: 0;
		padding: 30*$px 0;
		padding-left: 20*$px;
		padding-right: 20*$px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		position: relative;
		height: auto;
		.num{
			position: absolute;
			top: 0;left: 50%;
			@include transform(translate(-50%,-50%));
			z-index: 1;
			width: 40px;height: 40px;
			border-radius: 50%;
			border: 3px solid $kon;
			font-size: 30px;
			line-height: 1;
			color: $kon;
			@include flex-centering;
			background: #fff;
			@include num;
		}
		.img{
			display: flex;
			align-items: flex-end;
			margin-top: 20*$px;
		}
		.txt{
			width: auto;
			@include flex-centering;
			
			h3{
				font-size: 20*$px;
				text-align: center;
				position: relative;
				width: 100%;
				@include minchou;
				line-height: 1;
				img{
					position: absolute;
					width: 118*$px;
					top: -22px;
					right: -50px;
				}
				i{
					font-size: 25*$px;
					display: block;
					width: 100%;
					padding-bottom: 10*$px;
					margin-bottom: 10*$px;
					border-bottom: 1px solid $line;
				}
			}
			p{
				font-size: 14px;
				line-height: 1.75;
				padding-top: 20px;
				margin-bottom: 10*$px;
				br{display: none;}
			}
			.pic{
				line-height: 1;
				color: #e73817;
				height: 1em;
				font-size: 14*$px;
				position: relative;
				text-align: left;
				width: 100%;
				padding-top: 0;
				letter-spacing: .1em;
				margin-top: 10px !important;
				img{
					position: absolute;
					width: 117*$px;
					margin-top: -10px;
					top: 50%;right: -30px;@include transform(translateY(-50%));
				}
			}
		}
		&._2{
			.img{
				align-items: center;
				
			}
		}
		&._3{
			padding: 20*$px 0;
			.img{
				align-items: center;
				img{
					margin-left: -10px;
				}
			}
		}
	}//.seibun
}


	
	
/* !!------------------------------------ */
/* !! ★緑のDHA&EPA */
main.DHA-EPA{
	
	.mv{
		@include bg-img(url(../../product/DHA-EPA/img/mv-bg.jpg),cover,left bottom); 
		//$url,$size,$position,$repeat
	}
	
	h1{
		margin: 0 !important;	
		span{
			font-size: .8em;
			white-space: nowrap;
			line-height: 1.7 !important;
			display: block;
		}
	}
	.data{
		margin: 0 30*$px !important;
	}
	img.logo{
		width: 100% !important;
		height: auto !important;
		margin-bottom: 0;
	}

	.lead{
		h2{
			padding-bottom: 30*$px;
		}
		&._2{
			background: #F6F8E3;
			padding: 30*$px;
			h2{
				font-size: 18px;
				letter-spacing: .1em;
			}
			p{
				display: 14px;
				br{
					display: none;
				}
			}
		}
		
	}
	
	ul.img-list.mid.cen{
		
		li{
			width: 213px;
			span{
				white-space: nowrap;
			}
			&:last-of-type{
				margin: 0 auto;
			}
		}
	}
	
	
	.kodawari_wrap .inner{
		border-bottom: 1px solid $line;
		padding: 30*$px;
		h2{
			margin-bottom: 30*$px;
		}
	}

	.kodawari{
		position: relative;
		//overflow: hidden;
		height: auto !important;
		margin-bottom: 30*$px !important;
		&._1{
			display: block;
			position: relative;
			padding-right: 80px;
			.img{
				position: absolute;
				top: 0;right: 0;
				width: 98*$px;
				margin-right: -18*$px;
			}
			p{
				max-width: 614px;
				margin-right: 20px;
				font-size: 14px;
				line-height: 1.5;
			}
		}
		
		&._2{
			display: block;
			.img{
				margin-left: -30*$px;
				width: 100vw !important;
				max-width: 1000px;
				margin-bottom: 20*$px;
				img{
					width: 100% !important;
					height: auto !important;
				}
			}
			ul{
				width: 100%;
				li{
					margin-bottom: 10px;
				}
			}
		}
		
		&.r{
			display: block;
			.txt{
				order:2;
			}
			.img{
				order: 1;
				@include fitW(30);
				margin-bottom: 20px;
			}
		}
		
		
		
		.txt._3{
			overflow: hidden;
			width: 100%;
			margin: 0;
			display: block;
			p{
				font-size: 14px;
				font-weight: bold;
				letter-spacing: .1em;
				top: 0;
				line-height: 1;
				@include transform(translateY(0));
				strong{
					font-size: 22px;
					font-weight: bold;
					color: #E8380E;
					line-height: 1.3;
					i{
						font-size: 36px;
					}
				}
			}
		}


		.img{
			float: none;
		}
		ul{
			width: 50%;
			position: relative;
			@include flex-centering;
			align-items: center;
			align-content: center;
			//@include transform(translateY(-50%));
			li{
				width: 100%;
				background: #85CBBF;
				border-radius: 10px;
				padding: 15px;
				font-size: 1.6rem;
				font-weight: bold;
				color: #fff;
				margin-bottom: 15px;
				&:last-of-type{
					margin-bottom: 0;
				}
				p{
					@include note(1.5em);
					@include pfix(.6em);
				}
			}
		}
		&.left{
			.img{
				float: left;
				
			}
		}
	}
	
	p.point.red{
		color: #E8380E;
		text-align: center !important;
		font-size: 23*$px;
		@include minchou;
		strong{
			@include bg-img(url(../../product/DHA-EPA/img/wave.png),auto 5px,center bottom,repeat-x); //$url,$size,$position,$repeat
			padding-bottom: 10px;
		}
	}


	section.mo{
		.inner{
			padding-bottom: 0;
		}
		h2{
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 18*$px;
			i{
				font-weight: bold;
				margin: 0 5px;
				width: 43px;
				height: 43px;
				line-height: 1;
				font-size: 22px;
				display: inline-block;
				color: #FFF;
				@include flex-centering;
				display: -webkit-inline-flex;
				display: -moz-inline-flex;
				display: -ms-inline-flex;
				display: -o-inline-flex;
				display: inline-flex;
				@include bg-img(url(../../product/DHA-EPA/img/img2.png)); //$url,$size,$position,$repeat
			}
		}
		
		.hd{
			font-size: 18px;
			@include minchou;
			text-align:center;
			font-weight: normal;
			margin: 30px 0;
			line-height: 1;
		}
		
		.idx._1{
			dl{
				border: 1px solid #000;
				width: calc(50% - #{10*$px});
				dt{
					font-size: 14*$px;
					white-space: nowrap;
					background: #00437C;
					color: #fff;
					text-align: center;
					width: 90%;
					height:30px;
					border-radius: 15px;
					@include flex-centering;
					margin: 0 auto;
					margin-top: -15px;
					font-weight: bold;
				}
				
				dd{
					font-size: 14px;
					width: 100%;
					padding:10px 0;
					@include flex-centering;
				}
			}
		}
		
		.img.ac{
			@include flex-centering;
			height: 70px;
			img{
				width: 28px;
				height: 28px;
			}
		}
		
		.tBox{
			border: 1px solid #000;
			padding: 20px;
			padding-top: 80px;
			position: relative;
			margin-top: 80px;
			
			h3{
				background: #fff;
				width: 127px;
				margin: 0 auto;
				text-align: center;
				position: absolute;
				left: 50%;top: -85px;
				@include transform(translateX(-50%));
			}
			p{
				font-size: 14px;
				text-align: justify;
				br{
					display: none;
				}
				strong{
					font-size: 18px;
					font-weight: bold;
					display: block;
					line-height: 1;
					margin-bottom: 20px;
					@include minchou;
					letter-spacing: .1em;
				}
			}
		}
		
	}//section.mo
	
	//!! - ベータヴィア（ユーグレナ）を配合！
	section.betavia{
		.inner{
			padding-bottom: 0;
		}
		h2{
			font-weight: bold;
			margin-bottom: 15*$px;
			letter-spacing: .1em;
			white-space: nowrap;
			font-size: 20*$px;
		}
		.lead{
			line-height: 1.5;
			padding: 0;
			font-weight: bold;
			font-size: 14px;
			text-align: justify;
			
		}
		.idx._1{
			display: block;
			.img{
				text-align: center;
				margin-bottom: 15*$px;
			}
			p{
				width: auto;
				font-size: 14px;
			}
			width: auto;
			margin: 20*$px 0;
		}
		
		.feature{
			background: #F6F8E3;
			padding: 20*$px;
			h3{
				font-size: 20px;
				text-align: center;
				font-weight: normal;
				padding-bottom: 20px;
				line-height: 1;
				border-bottom: 1px solid $line;
				letter-spacing: .1em;
				@include minchou;
			}
			ul{
				margin-top: 30px;
				font-weight: bold;
				font-size: 18px;
				line-height: 1.7;
				padding-top: 20px;
				@include pfix(.7em);
				li{
					font-size: 14px;
					letter-spacing: .1em;
					@include note;
					i{
						color: #8DC21E;
					}
				}
			}
		}
		
	}//section.betavia
	
	//!! - 現代の日本人に不足している成分「オメガ3」
	section.omega3{
		h2{
			font-size: 24px;
			margin-bottom: 20px;
		}
		.inner{
			padding-bottom: 0;
		}
		.lead{
			padding: 0;
			text-align: justify;
		}
		
		p{
			font-size: 14px;
		}
		.txt1{
			@include flex-centering;
			margin: 20px 0;
			margin-bottom: 30px;
			position: relative;
			.wrap{
				position: relative;
				width: 80%;
			}
			p{
				position: absolute;
				left: 0;
				height: 100%;
				width: 100%;
				top: 5px;
				@include flex-centering;
				font-size: 18*$px;line-height: 1.82;
				font-weight: bold;	
				text-align: center;
			}
		}
		
		.txt-wrap{
			position: relative;
			@include fitW(30);
			.txt{
				width: auto;
				
				height: auto;display: block;
				border: 1px solid #000;
				background: #fff;
				margin: 0 30*$px;
				position: relative;
				z-index: 1;
				@include flex-centering;
				padding: 20*$px;
				margin-top: -60*$px;
				h3{
					font-size: 20px;
					width: 100%;
					text-align:center;
					@include minchou;
					line-height: 1;
					letter-spacing: .03em;
					padding-bottom: 20px;
					border-bottom: 1px solid #000;
				}
				p{
					font-size: 14px;
					line-height: 1.75;
					@include pfix(.75em);
					padding-top: 20px;
				}
			}
		}
		
	}//section.omega

	

	
}
	


/* !!------------------------------------ */
/* !! ★還元型コエンザイムQ10 */
main.Q10{
	
	.mv{
		@include bg-img(url(../../product/Q10/img/mv-bg.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	div.img-right.block{
		margin-top: 50*$px;
		.img{
			max-width: 100% !important;
			img{
				max-width: 100% !important;
			}
		}
		.txt{
			padding-right: 0px;
		}
		h3{
			line-height: 1.75;
			@include pfix(.75em);
			padding-bottom: 30*$px !important;
		}
		h4{
			font-size: 2rem;
			line-height: 1;
			margin-bottom: 20px;
		}
	}
	
	div.img-left.block{
		.txt{
			margin-top: 30*$px;
		}
	}
	
	.img-list li{
		margin-bottom: 0 !important;
	}
	
	p.ac{
		text-align: center;
	}
	
	ul.img-list.food{
		max-width: 600px;
		margin: 0 auto;
		li{
			margin: 0 25px 50px 25px !important;
			&:nth-last-of-type(-n+3){
				margin-bottom: 0 !important;
			}
		}
	}
	
	p.hd{
		font-size: 2rem;
		line-height: 1;
		margin-bottom: 20px;
		strong{
			font-size: 1.2em;
			font-weight: 600;
			display: block;
			margin-bottom: 20px;
		}
	}
	
	ul.idx.vitamin{
		margin: 0 auto;
		text-align: center;
		margin-bottom:0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		li{
			max-width: 415px;
			display: inline-block;
			width: 100%;
			height: 100px;
			position: relative;
			margin: 20*$px 0;
			border: 1px solid #fff;
			border-radius: 10px;
			p{
				text-align: center;
				font-size: 3rem;
				font-weight: 600;
				line-height:1.33;
				position: relative;
				color: #fff;
				top: 50%;
				@include pfix(.33em);
				@include transform(translateY(-50%));
				span{
					display: block;
					font-size: 0.5em;
				}
			}
		}
		
	}

}



/* !!------------------------------------ */
/* !! 魔法のワハハ歯ブラシ */
main.wahaha{
	
	.mv{
		//background-color: #fff;
		@include bg-img(url(../../product/wahaha-haburashi/img/mv_sp.jpg)); //$url,$size,$position,$repeat
		-webkit-background-size: 100% auto !important;
		background-size:  100% auto !important; 
		.logo{
			max-width: 60%;
			margin-bottom: 0;
		}
	}
	
	.block{
		margin-top: 100*$px;
		.inner{
			position: relative;
			width: auto !important;
			border: 1px solid $text;
			padding: 10*$px 0 !important;
			h2{
				position: absolute;
				top: 0;left: 0;
				width: 100%;
				height: 60px;
				font-size: 18px !important;
				@include flex-centering;
				@include transform(translateY(-50%));
				i{
					display: block;
					font-size: 18px;
					background: #fff;
					padding: 0 10*$px;
					letter-spacing: .1em;
				}
			}
		}
	}
	
	#_1{
		margin-top: 100*$px;
		
		.inner{
			padding-top: 60*$px !important;
			border: none !important;
			padding-bottom: 0 !important;
		}
		ul.idx{
			max-width: 690*$px;
			margin: 0 auto;
			padding: 0 30px;
			
			li{
				width: calc((100% - #{15*$px}) / 2 );
				height: 50*$px;
				font-size: 20px;
				font-weight: bold;
				color: #fff;
				margin-bottom: 20*$px;
				background: #656464;
				@include flex-centering;
				border-radius: 5px;
				&.b{
					background-color: #1c2585;
				}
				&.r{
					background-color: #8c1d25;
				}
				&:nth-of-type(n+5){
					margin-bottom: 20*$px;
				}
			}
		}
	}
	
	#_2{
		margin-top: 60*$px;
		.inner{
			margin: 0 30*$px;
			padding: 15*$px !important;
			padding-top: 40*$px !important;
		}
		p{
			text-align: center;
		}
	}
	
	
	#_3{
		max-width: 1000px;
		margin: 50*$px 0;
		position: relative;
		._1{
			line-height: 1.5;
			position: relative;
			
			bottom: 0px;
			left: 0;
			width: 100%;
			padding: 10px 30*$px;
			padding-left: 80*$px;
			text-align: left;
			z-index: 1;
			
		}
	}
	
	#_4{
		margin: 0 30*$px;
		margin-top: 50px !important;
		.inner{
			max-width: 1000px;
			padding: 30px 20px !important;
			padding-bottom: 20px !important;
		}
		p{
			br{
				display: none;
			}
		}
		p._1{
			@include minchou;
			font-size: 16px;
			width: 60%;
			padding-right: 10px;
			align-self: flex-start;
		}
		
		.img{
			position: relative;
			width: 40%;
			img{
				width: 100% !important;
				height: auto !important;
			}
			p{
				right: 0;
				line-height: 1;
				text-align: right;
				bottom: -10px;
				@include transform(translateY(100%));
				position: absolute;
				white-space: nowrap;
				font-weight:900;
				font-style: 18px;
				font-size: 12px;
				i{
					display: block;
					font-size: 12px;
					margin-bottom: 5px;
				}
			}
		}
		
		h3{
			text-align: center;
			font-size: 16px;
			margin: 20px auto;
			margin-top: 70px;
			&:after{
				content: "";
				height: 1px;
				width: 100%;
				display: block;
				background: #333;
				@include transform(translateY(-15px));	
			}
			i{
				display: inline-block;
				position: relative;
				z-index: 1;
				line-height: 30px;
				background: #fff;
				font-size: 16px;
				letter-spacing: .1em;
				padding: 0 10px;
				@include minchou;
			}
		}
		
		div._1{
			background: #E9EFF8;
			padding: 20*$px;
			p{
				@include minchou;
				margin: 0;
			}
		}
	}
	
	div.sec{
		margin: 50*$px 30*$px;
		border: 1px solid $text;
		height: auto;
		display: block;
		align-items: center;
		padding: 10px;
		h2{
			width: auto;
			border-right:0;
			border-bottom: 1px solid $text;
			height: auto;
			line-height: 1;
			padding-bottom: 10px;
			font-size: 18px;
		}
		ul{
			margin-top: 10px;
			li{
				position: relative;
				@include note;
				font-size: 13px;
				i{
					color: #0B308D;
				}
			}
		}
	}
	
	
	#_5{
		.img{
			text-align: center;
			margin: 50*$px 0;
		}
		
		.howto{
			margin: 0 30*$px;
			border: 1px solid #8F1C23;
			max-width: 1000px;
			h3{
				background: #8F1C23;
				color: #fff;
				height: 50px;
				font-size:18px;
				@include flex-centering;
				letter-spacing: .1em;
			}
			.txt{
				padding: 15*$px;
				align-items: center;
			}
			p{
				max-width: 616px;
			}
			.img{
				margin: 0 auto;
				margin-top: 15px;
			}
		}
	}
	
	#_6{
		.inner{
			margin: 30*$px;
			width: auto !important;
			display: block;
			@include flex-centering;
			padding: 30px !important;
			&:before{
				content:"";
				@include innerRect(5px);
				border: 1px solid #000;
				pointer-events: none;
			}	
		}
		.img._1{
			position: relative;
			margin-bottom: 10px;
		}
		
		.img._2{
			width: 100%;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		.txt{
			.hd{
				line-height: 1;
				font-size: 16px;
				margin-bottom: 20px;
				font-weight: bold;
				text-align: left;
				margin: 15px 0;
				line-height: 1.5;
			}
			ul{
				font-size: 14px;
				font-weight: bold;
			}
		}
	}
	
	#_7{
		margin: 50*$px 30*$px;
	}
	.nutrient{
		margin: 0 !important;
		th{
			width: auto !important;
		}
	}
	
	
}//main.wahaha






/* !!------------------------------------ */
/* !! 安心・安全への取り組み */

main.forsafety{
	
	>section{
		position: relative;
		&:first-of-type{
			margin-top: 50*$px;
		}
		
		padding:30*$px 50*$px;
		padding-bottom: 0;
		
		margin: 0;
		height: auto;
		position: relative;
		h2{
			padding-left: 0;
			margin-left: 0;
			&:after{
				content: none;
			}
			& + p{
				margin-top: 15px;
				white-space: normal;
				text-align: justify;
				br{
					display: none;
				}
			}
		}
		
		.wrap{
			position: static;
			z-index: 1;
			left: 100px;top: 50%;
			@include transform(translateY(0));
		}
		
		.img{
			position: static;
			width: 200px !important;
			top: 0;right: 0;
			margin: 0 auto;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		&.kokunai{
			@include gd-1;
		}
		
		&.seibun{
			.img{
				left: 0;
				right: auto;
				z-index: 0;
			}
			background: -webkit-linear-gradient(90deg ,#f9f9f9 0%, #fefefe 100%);
			.wrap{
				left: auto;
				right: 100px !important;
			}
		}
		&.privacy{
			overflow: hidden;
			@include gd-2;
			margin-bottom: 100px;
			.img{
				position: static;
				top: 0;right: 0;
				float: right;
				margin-right: -50*$px;
			}
		}
	}
	
	
	
}//main.forsafety





/* !!------------------------------------ */
/* !! 企業情報 */

main.company{
	
	.lead-wrap{
		max-width: 1000px;
		position: relative;
		margin: 50px auto;
		width: 100%;
		.img{
			position: relative;
			top: 0;
			left: 0;
			z-index: 0;
			width: 100%;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		.lead{
			//position: relative;
			//z-index: 1;
			margin-left: 0;
			//background: #EAF6FD;
			padding: 30*$px;
			p{
				//font-size: 1.6rem;
				//line-height: 1.75;
				//@include pfix(.75);
			}
		}
	}
	
	h2.cen{
		//text-align: center;
		//padding: 0 !important;
		margin-bottom: 50px;
		margin-left: 0;
		width: 100%;
		line-height: 1;
		&:after{
			content: none;
			
		}
	}
	
	.philosophy{
		padding: 0;
		padding: 0 30*$px;
		.inner{
			width: 100%;
			margin: 0 auto;
		}
		
		
		ul{
			display: block;
			justify-content: space-between;
			li{
				width: 100%;
				border-bottom: 0 !important;
				&:last-of-type{
					border-bottom: 1px solid $line !important;
				}
				strong{
					line-height: 1;
					display: block;
					border: 1px solid $line;
					border-width: 1px 0;
					font-size: 1.8rem;
					line-height: 40px;
					text-align: center;
					font-weight: 600;
				}
				p{
					padding: 30px 0;
					@include pfix(.75em);
				}
			}
		}
		
	}//.philosophy
	
	
	//!! 会社概要
	section.outline{
		padding: 50*$px 0 !important;
	}
	
	ul.outline{
		
		li{
			height: auto;
			div{
				position: relative;
				line-height: 1.6;
				font-size: 1.6rem;
				max-width: 1000px;
				margin: 0 auto;
				margin: 0 30*$px;
				top: 0;
				padding: 10px 0;
				position: relative;
				padding-left: 100px;
				@include transform(translateY(0));
				span{
					display: inline-block;
					width: 100px;
					position: absolute;
					top: 10px;
					left:0;
				}
			}
			&:nth-of-type(2n + 1){
				background: #F7F8F8;
			}
		}
	}
	
	
}//main.company




/* !!------------------------------------ */
/* !! コンタクト */

main.contact{
	
	section#form-sec{
		text-align: center;
		padding-bottom: 0;
		padding: 30*$px !important;
	}
	
	p.hd{
		text-align: center;
		font-size: 1.8rem;
		font-weight: 600;
		line-height: 1;
		margin-bottom: 0px;
	}
	
	
	.mfp_err{
		line-height: 1.3;
		padding:8px 0 !important;
		//display: block !important;
		white-space: normal;
		font-size: 1rem;
		&:before{
			content: "※";
		}
	}
	.cb-grp{
		width: 100%;
		margin: 0px auto !important;
		display: block;
		& + .mfp_err{
			text-align: center;
		}
		>div{
			width: 100%;
			height: auto;
			padding: 15px;
			margin-top: 20px;
			background: #F7F8F8;
			margin-bottom: 0;
			&:last-of-type(-n + 3){
				margin-bottom: 30px !important;
			}
			label{
				display: block;
				position: relative;	
				line-height: 1;
				padding-left: 0px;
				text-align: left;
				line-height: 1;
				font-size: 1.6rem;
				top: 0;
				input{
					display: inline-block;
					margin-right: 10px;
				}
				@include transform(translateY(0));
			}
		}
	}
	
	.contact-wrap{
		background: #F7F8F8;
		padding: 50px 0;
		margin-top: 30px;
		@include fitW(30);
		padding: 30*$px;
	}
	
	ul.form-grp{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin: 30*$px auto;
		justify-content: space-between;
		li{
			width: 100%;
			height: auto;
			margin-bottom: 20*$px;
			position: relative;
			font-size: 1.6rem;
			span{
				position: relative;
				top: 0;left: 0;
				display: block;
				text-align: left;
				margin-bottom: 5px;
				@include transform(translateY(0));
			}
			input{
				appearance: none;
				padding: 10px;
				border: 1px solid $line;
				height: 40px;
				width: 100%;
				display: block;
				font-size: 1.6rem;
			}
			&.full{
				width: 100% !important;
				height: auto;
				.zip{
					height: 40px;
					width: 200px;
					float: none;
					margin-bottom: 20px;
					&:before{
						//content: "〒";
						//position: absolute;
						//bottom: 0;left: 0;
						width: 40px;
						text-align: center;
						line-height: 40px;
					}
					input{
						padding-left: 40px;
					}
				}
				div.add{
					width: 100%;
					height: 40px;
					float: none;
					clear: right
				}
			}
			&.inquiry{
				width: 100% !important;
				height: auto;
				margin-bottom: 0 !important;
				textarea{
					padding: 10px;
					font-size: 1.6rem !important; 
					border: 1px solid $line;
					display: block;
					max-width: 1000px;
					width: 100% !important;
					height: 200px !important;
				}
			}
		}
	}
	
	.bt_wrap{
		font-size: 0;
		button{
			position: relative;
			appearance: none;
			border: none;
			width: 100%;
			height: 50px;
			line-height: 50px;
			background: #666;
			color: #fff;
			font-size: 1.6rem;
			font-weight: bold;
			letter-spacing: .1em;
			padding: 0;
			
			margin: 0;
			margin-bottom: 10px;
			&:after{
				@include anchor(#fff,8px);
			}
			&.submit{
				@include gd-BLU;
			}
		}
	}
	
	
}//main.contact



/* !! - - -  */
/* !! 確認画面 */
table#mfp_confirm_table{
	max-width: 800px;
	width: 100%;
	border: 1px solid $line;
	margin: 50px auto;
	margin-bottom: 30px;
	th,td{
		display: block !important;
		text-align: left;
	}
	td{
		padding-top: 0 !important;
	}
}


.mfp_buttons{
	margin-bottom: 50px;
	font-size: 0;
	button{
		position: relative;
		appearance: none;
		border: none;
		width: 100%;
		height: 50px;
		line-height: 50px;
		background: #666;
		color: #fff;
		font-size: 1.6rem;
		font-weight: bold;
		letter-spacing: .1em;
		padding: 0;
		margin: 0;
		margin-bottom: 10px;
		&:after{
			@include anchor(#fff,8px);
		}
		&#mfp_button_send{
			@include gd-BLU;
		}

	}
}

/* !! - - -  */
/* !! 完了画面 */
div.thanks{
	border-radius: 10px;
	width: auto;
	padding: 30px;
	background: #F7F8F8;
	margin: 30px 30*$px;
	margin-bottom: 30*$px;
	& + .btn{
		margin: 0 auto;
		margin-bottom: 50px;
	}
}



}// media