@charset "UTF-8";
/* CSS Document */
@mixin sp {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: 768px) {
    @content;
  }
}


$orange:#EA5532;
@mixin flex{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
}

@mixin flex-centering{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

@mixin flex-start{
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
}

@mixin flex-end{
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	justify-content: center;
}


$font01 : 'DIN','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','YuGothic', 'Yu Gothic','游ゴシック','メイリオ','Meiryo',sans-serif;
$pink:#d94667;

* , :before , :after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	color:inherit;
	text-decoration: none;
	transition: all 0.3s linear;
}

ul , ol {
	list-style: none;
}

h1 , h2 , h3 , h4 , ul , ol , dl , dd , p , hr , figure {
	margin: 0;
	padding: 0;
}

header,nav,main,footer,section{
	display: block;
}

strong {
	font-weight: normal;
}

form {
	margin-bottom: 0;
}



input::selection,
textarea::selection {
	color: #221814;
	background: #dbdcdc;
}

.collection main{
	overflow: hidden;
}

.noLink{
	text-decoration: none !important;
	cursor: default;
}

p{
	line-height: 1.75;
	//@include gothic;
	font-size: 1.6rem;
	text-align: justify;
}

.ar{
	text-align: right;
}

i{
	font-style: normal;
}

.fr{
	float: right;
}

/* !!------------------------------------ */
/* !! 共通css */
html{
	font-size: 62.5%;
}

body{
	margin: 0;
	padding-top: 95px;
	color: $text;
	background: #fff;
	@include gothic;
	font-feature-settings: "palt";
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

div#shade{
	//position: fixed;
	width: 100% !important;
	height: 100% !important;
	background: #fff;
	top: 0;left: 0;
	z-index: 1000;
}

div#view{
	height: 100vh;
}

#container{
	//display: none;
	min-width:1200px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	
}

.inner {
	width: 1200px;
	height: 100%;
	margin: 0 auto;
	position: relative;
	
}

img{
	backface-visibility:hidden;
	-webkit-backface-visibility: hidden;
}

.notf{
	@include transform(none !important);
}



a.na{
	opacity: .5;
	pointer-events: none;
}

div:not(#main_middle) .btn,
#site-header a.btn{
	width: 250px;
	height: 40px;
	border-radius: 20px;
	@include gd-GR;
	display: block;
	line-height: 40px;
	text-align: center;
	color: #fff;
	font-weight: 600;
	font-size: 1.6rem;
	position: relative;
	
	&:after{
		@include anchor($color:#fff);
	}
	&.cart{
		@include gd-OR;
		&:after{content:none;}
		&:before{
			content: "";
			display: inline-block;
			width: 21px;
			height: 20px;
			vertical-align: middle;
			margin-right: 10px;
			position: relative;
			top: 0px !important;
			@include bg-img(url(../img/icon-cart.png)); 
		}
	}
}

/* !!------------------------------------ */
/* !! header */


#site-header{
	
	background: #fff;
	height: 95px;
	position: fixed;
	top: 0;left: 0;z-index: 10;
	width: 100%;
	min-width: 1200px;
	border-bottom: 1px solid #eee;
	.h1txt{
		position: absolute;
		left: 50px;top: 50%;
		@include transform(translateY(-50%));
		&.top{
			display: none;
		}
	}
	
	body.top & .h1txt{
		display: none;
		&.top{
			display: block;
		}
	}
	
	a.btn{
		width: 220px;
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		right: 50px;
		font-weight: bold;
	}

}


ul.gnav{
	position: absolute;
	right: 300px - 12;
	top: 50%;
	@include transform(translateY(-50%));
	li{
		display: inline-block;
		a{
			line-height: 40px;
			display: block;
			font-weight: 500;
			font-size: 1.6rem;
			padding: 12px;
			
		}
	}
}






/* !!------------------------------------ */
/* !! トップページ */

.top .mv{
	width: 100%;
	height: calc((100vh - 95px)/2);
	position: relative;
	p{
		position: absolute;
		top: 50%;
		left: 50%;
		height: 27px;
		z-index: 1;
		@include transform(translate(-50%,-50%));
		img{
			display: block;
		}
	}
	&.inv{
		.img{
			@include filter(blur(4px));
			transform: scale(1.05);
		}
	}
	.img{
		width:100%;
		height:100%;
		transition: 5000ms;
		@include filter(blur(0px));
		transform: scale(1);
		@include bg-img(url(../../img/top/mv.jpg),cover); //$url,$size,$position,$repeat
	}
	
	a.scroll{
		position: absolute;
		z-index: 1;
		bottom: 0;left: 50%;
		height: 53px;
		@include transform(translateX(-50%));
		img{
			height: auto;
			display: block;
		}
	}
	
}//.mv

.mv{
	position: relative;
}

h2{
	@include minchou;
	font-size: 2.4rem;
	font-weight: normal;
	letter-spacing: .1em;
	position: relative;
}

.top main{
	background: #fff;
	display: block;
	position: relative;
	z-index: 3;
	min-width: 1200px;
	overflow: hidden;
	>section{
		display: block;
		padding: 100px 0;
		.inner{
			max-width: 1200px;
			height: 100%;
			margin: 0 auto;
		}
		
		h2{
			padding-left: 110px;
			&:after{
				content: "";
				width: 100px;
				height: 1px;
				display: block;
				background: $text;
				position: absolute;
				left: 0;top: 10px;
			}
			span{
				font-size: 0.58em;
				display: block;
			}
		}
	}
}



/* !! - - -  */
/* !! エーエルジャパン製品案内 */

//!! - スライド
#slide{
	margin-top: 50px;
	text-align: center;
	font-size: 0;
	white-space: nowrap;
  opacity: 0;
  &.show{
    opacity: 1;
  }
	.cell{
		*:focus{
			outline: none;
		}
    width: 200px !important;
		display: inline-block;
		background: #fff;
    border-radius: 12px;
    //transition: 500ms background;
    &:hover{
      background: #eef8ff;
    }
		width: 200px;height: 200px;
		margin: 0 25px;
		position: relative;
		a{
			width:100%;
			height:100%;
			display: block;
		}
		img{
			height: 150px !important;
			width: auto !important;
			display: block;
			@include centering;
		}
	}
	button{
		cursor: pointer;
	}
	.slick-prev{
		background-color: transparent;
		width: 18px;
		height: 32px;
		font-size: 0;
		border: none;
		position: absolute;
		left: -50px;
		top: 50%;
		@include transform(translateY(-50%));
		@include bg-img(url(../img/anchor-l.png)); 
	}
	.slick-next{
		background-color: transparent;
		width: 18px;
		height: 32px;
		font-size: 0;
		border: none;
		position: absolute;
		right: -50px;
		top: 50%;
		@include transform(translateY(-50%));
		@include bg-img(url(../img/anchor-r.png)); 
	}
}


/* !! - - -  */
/* !! 安心安全への取り組み */
#forsafety{
	margin-bottom: 100px;
	position: relative;
	.inner{
		padding-left: 600px;
		width: 1200px;
	}
	@include gd-1;
	.img{
		position: absolute;
		bottom: 0;left: 0;
		z-index: 0;
	}
	h2{
		position: relative;
		margin-left: -50px;
	}
	p{
		margin: 40px 0;
		margin-left: 60px;
	}
	a.btn{
		
	}
	
	.btn{
		margin-left: 60px;
		position: relative;
		z-index: 1;
	}
}




/* !! - - -  */
/* !! 定期注文をおすすめする理由 */
section#outline{
	position: relative;
	@include gd-2;
	
	.img{
		position: absolute;
		bottom: 0;right: 0;z-index: 0;
	}
	
	strong{
		font-size: 2rem;
		line-height: 1.7;
		@include minchou;
		display: block;
		margin: 25px 0 20px 0;
	}
	p{
		margin-left: 110px;
		@include pfix(0.88em)
	}
	
}




/* !! - - -  */
/* !! お知らせ */
ul.topics-list{
	margin-left: 110px;
	margin-right: 100px;
	li{
		&:after{
			@include anchor();
		}
		position: relative;
		padding: 20px 0 ;
		font-size: 1.4rem;
		border-bottom: 1px solid $line;
		>div{
			display: inline-block;
			vertical-align: top;
		}
		.date{
			line-height: 20px;
		}
		.cate{
			background: #FCD475;
			width: 100px;
			height: 20px;
			line-height: 20px;
			text-align: center;
			margin: 0 30px;
		}
    a{
      color: rgb(0, 106, 255);
      text-decoration: underline;
    }
	}
}





/* !! - - -  */
/* !! フッター */

#page-footer{
	.wrap{
		background: #EFEFEF;
		text-align: center;
		padding: 50px 0;
		a{
			vertical-align: top;
			
		}
		.tel{
			width: 403px;
			display: inline-block;
			img{
				width: 100% !important;
				height: auto !important;
			}
		}
		.btn.contact{
			margin-left: 50px;
			display: inline-block;
			@include gd-BLU;
			border-radius: 0;
			width: 300px;
			height: 60px;
			margin-top: 11px;
			line-height: 60px;
			font-weight: bold;
		}
	}
	&.contact{
		.btn.contact{
			display: none !important;
		}
	}
	#fnav{
		text-align: center;
		padding: 50px 0;
		ul{
			position: static;
			@include transform(translateY(0));
			li{
				display: inline-block;
				line-height: 1;
				font-size: 1.6rem;
				padding: 0;
				a{
					line-height: 1;
					padding: 0 15px;
				}
			}
		}
	}
	p.copy{
		text-align: center;
		line-height: 50px;
		font-size: 1.2rem;
		border-top: 1px solid $line;
	}
}


/* !!------------------------------------ */
/* !! 2階層以降共通 */
.path{
	max-width: 1200px;
	margin: 0 auto;
	font-size: 0;
	
	font-size: 1.4rem !important;
	li{
		display: inline-block;
		line-height: 20px;
		margin-top: 10px;
		&:after{
			content: " ＞ ";
		}
		&:last-of-type:after{
			content: none;
		}
		a:hover{
			text-decoration: underline;
		}
	}
}

main.page{
	
	.page-title{
		margin-top: 30px;
		text-align: center;
		@include minchou;
		font-size: 2.4rem;
		font-weight: 500;
		letter-spacing: .1em;
		position: relative;
		span{
			font-size: 0.58em;
			display: block;
		}
		& + .lead{
			padding-top: 20px;
			text-align: center;
			@include pfix(.8em);
		}
	}
	
	
}//main.page


/* !!------------------------------------ */
/* !! エーエルジャパン製品の案内 */

main.product{
	
	.mv{
		margin-top: 50px;
		height: 480px;
		padding: 0;
		overflow: hidden;
		@include bg-img(url(../../product/img/mv-bg.jpg),cover); //$url,$size,$position,$repeat
		p{
			width: 70%;
			aspect-ratio:6.78/1;
      @include pc{
         min-width: 774px;
         max-width: 1366px;
      }
     
      //background: #eee;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%,-50%));
			img{
				position: absolute;
				width: 100% !important;
				height: auto !important;
				top: 0;left: 0;
			}
		}
	}
	
	>section{
		//background: #eee;
		padding: 0;
		//margin: 50px 0;
		font-size: 0;
		text-align: right;
    position: relative;
    @include sp{
      margin: 0 !important;
    }
    &:before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;left: 0;
      background: #eee;
      @include sp{
        opacity: .7;
      }
    }
		&:last-of-type{
			margin-bottom: 100px;
		}
		.inner{
			padding: 100px 50px;
      position: relative;
      z-index: 2;
		}

		.item{
			height: 280px;
			display: inline-block;
			text-align: right;
			position: relative;
			.img{
				float: right;
				margin-left: 30px;
        @include sp{
          margin: 0 !important;
        }
			}
			.txt{
				//background: #fff;
				display: inline-block;
				height: 100%;
				font-size: 0;
				position: relative;
				p.catch{
					font-size: 2.4rem;
					line-height: 1.6em;
					text-align: right ;
					@include minchou;
					white-space: nowrap;
					@include pfix(.75em);
				}
				.data{
					position: absolute;
					bottom: 0;right: 0;
					p.name{
						font-size: 1.8rem;
						font-weight: 600;
						text-align: right;
						width: auto;
						text-align: right;
						padding-bottom: 8px;
						border-bottom: 1px solid $line;
						white-space: nowrap;
						i.cont{
							font-size: .66em;
							padding-left: 10px;
							@include sp{
								display: block;
								padding-left: 0;	
							}//@sp
						}
					}
					p.desc{
						text-align: right;
						padding: 15px 0 30px 0;
						@include pfix(.8em);
            @include pc{
              white-space : nowrap ;
            }
					}
					.btn{
						display: inline-block;
						margin: 0 auto;
						margin-right: 0;
            @include sp{
              display: block !important;
              margin: 0 auto !important;
            }
					}
						
				}
			}
			
		}
		
		&.left,
    &:nth-of-type(2n + 1)
    {
			text-align: left;
			.item{
				text-align: left !important;
			}
			.img{
				float: left;
				margin: 0;
				margin-right: 30px;
			}
			p.catch{
				text-align: left !important;
			}

			.data{
				bottom: 0;right:auto;
				left: 0;
				p.name{
					text-align: left !important;
				}
				p.desc{
					text-align: left !important;
				}
				.btn{
					display: inline-block;
					margin-right: auto !important;
					margin-left: 0 !important;
				}
					
			}
      &:before{
       
        @include pc{ transform: rotateY(180deg);}
      }
		}
		
		.catch.outer{
			@include minchou;
			line-height: 1;
			font-size: 2.4rem;
			text-align: center;
		}
		
		.idx{
			margin-top: 80px;
			display: flex;
			justify-content: space-between;
			>div.left{
				width: 504px;
			}
			>div.right{
				width: 534px;
			}
		}
		
		
		
	}//>section
	
	.premium-croire:before{
		//@include bg-img(url(../../product/img/bg01.jpg),cover); 
		@include bg-img(url(../../product/img/561.jpg),cover);
    @include sp{
      @include bg-img(url(../../product/img/561_sp.jpg),contain,top center);
    }
	}
	
	.probiotics12:before{
		.inner{padding-bottom: 50px !important;}
		@include bg-img(url(../../product/img/572.jpg),cover);
    @include sp{
      @include bg-img(url(../../product/img/572_sp.jpg),contain,top center);
    }
	}

  .croire-eye-q:before{
		.inner{padding-bottom: 50px !important;}
		@include bg-img(url(../../product/img/581.jpg),cover);
    @include sp{
      @include bg-img(url(../../product/img/581_sp.jpg),contain,top center);
    }
	}
	
	.DHA-EPA:before{
		@include bg-img(url(../../product/img/569.jpg),cover); 
    @include sp{
      @include bg-img(url(../../product/img/569_sp.jpg),contain,top center);
    }
	}
	
	.NA:before{
		@include bg-img(url(../../product/img/585.jpg),cover); 
    @include sp{
      @include bg-img(url(../../product/img/585_sp.jpg),contain,top center);
    }
	}
	
	.Q10:before{
		@include bg-img(url(../../product/img/554.jpg),cover); 
    @include sp{
      @include bg-img(url(../../product/img/554_sp.jpg),contain,top center);
    }
	}

	.croire-gold:before{
		@include bg-img(url(../../product/img/526.jpg),cover); 
    @include sp{
      @include bg-img(url(../../product/img/526_sp.jpg),contain,top center);
    }
	}
	
	.wahaha-haburashi:before{
		@include bg-img(url(../../product/img/518.jpg),cover); 
    @include sp{
      @include bg-img(url(../../product/img/518_sp.jpg),contain,top center);
    }
	}

  .coat65:before{
		@include bg-img(url(../../product/img/535.jpg),cover); 
    @include sp{
      @include bg-img(url(../../product/img/535_sp.jpg),contain,top center);
    }
	}
	
	
}//main.product

/* !!------------------------------------ */
/* !! ★商品単体ページ共通 */

ul.path.product{
	top: 0;
	position: relative;
	z-index: 2;
	height: 50px;
}


main.single{	
	
	margin-top: -50px;
	position: relative;
	z-index: 1;
	
	h2{
		border: none !important;
		i{
			@include minchou;
		}
	}
	
	.mv{
		height: 400px;
		overflow: hidden;
		background-colo: none  !important;
		-webkit-background-size: cover;
		background-size: cover !important;
		.inner{
			//background: #ddd;
			position: relative;
			height: auto;
			max-width: 1200px;
			height: 260px;
			margin: 70px auto !important;
			
			.img{
				float: right;
				margin-right: 50px;
			}
		}
		
		.data{
			height: 260px;
			float: right;
			margin-right: 50px;
			position: relative;
		}
		
		h1{
			text-align: right;
			@include minchou;
			font-size: 2.4rem;
			font-weight: normal;
			@include pfix(.71em);
			line-height: 1.71;
			letter-spacing: .1em;
			position: relative;
			top: 82px;
		}
		.logo{
			position: absolute;
			bottom: 0;right: 0;
		}
	}
	
	
	.lead{
		padding: 100px 0;
		text-align: center;
		h2{
			line-height: 1;
			padding-bottom: 30px;
		}
		p{
			text-align: center !important;
			@include pfix(.8em);
		}
	}
	
	
	section.about{
		overflow: hidden;
		
		background: #EAF6FD;
		.inner{
			padding: 0;
			max-width: 1000px;
			margin: 100px auto !important;
			
			h2.Q{
				line-height: 1;
				text-align: left;
				border-bottom: 1px solid $line;
				padding-bottom: 20px;
				margin-bottom: 0 !important;
			}
			p.A{
				padding-top: 20px;
				text-align: justify;
				@include pfix(.75em);
			}
		}		
		
	}//section.about
	
	h2.bd{
		line-height: 1;
		text-align: left !important;
		border-bottom: 1px solid $line;
		padding-bottom: 20px;
		margin-bottom: 0 !important;
		margin-top: 50px !important;
	}
	
	>section{
		.inner{
			max-width: 1000px;
			margin: 0 auto;
			padding: 100px 0;
			h2{
				line-height: 1.75;
				text-align: center;
				@include pfix(.75em);
				margin: 100px 0 50px 0;
				&:first-of-type{
					margin-top: 0;
				}
			}
		}
	}
	
	h3.border{
		max-width: 383px;
		line-height: 38px;
		border: 1px solid $text;
		text-align: center;
		border-radius: 20px;
		font-size: 2rem;
		font-weight: bold;
		margin: 50px auto;
	}
	
	
	//!! - イメージリスト
	ul.img-list{	
		font-size: 0;
		text-align: center;
		&._50{
			li{
				margin: 0 25px;
			}
		}
		li{
			width: 150px;
			margin: 0 15px;
			margin-bottom: 30px;
			display: inline-block;
			vertical-align: top;
			.img{
				width: 100%;
				height: 150px;
				overflow: hidden;
				background: #eee;
				border-radius: 50%;
				img{
					width: 100% !important;
					height: auto !important;
					display: block;
				}
			}
			span{
				text-align: center;
				display: block;
				margin-top: 10px;
				font-size: 1.6rem;
				//background: #eee;
				width: 200%;
				left:50%;
				letter-spacing: .05em;
				@include transform(translate(-50%,0));
				position: relative;
				b{
					display: block;
					font-size: 1.13em;
					i{
						font-size: 1.22em;
					}
				}
			}
		}
		
		&.cen{
			span{
				text-align: center !important;
			}
		}
		
		//!! - - 中サイズ
		&.mid{
			li{
				width: 195px;
				vertical-align: top;
				.img{
					height: auto;
				}
				span{
					width: 100% !important;
					left: 0;
					@include transform(translate(0));
					text-align: justify;
				}
			}
		}
		
		//!! - - 大サイズ
		&.lg{
			li{
				width: 250px;
				vertical-align: top;
				&:nth-last-child(-n+3){
					margin-bottom: 0;
				}
				.img{
					height: auto;
				}
				span{
					padding-top: 20px;
					margin-top: 20px;
					@include pfix(.7em);
				}
			}
		}
		
	}//ul.img-list
	
	
	ul.img-list2{
		
		display: flex;
		justify-content: space-between;
		li{
			width: calc((100% - 90px)/4);
			.img{
				background: #fff;
				height: 200px;
				position: relative;
				img{
					width: 100% !important;
					height: auto !important;
				}
				i{
					position: absolute;
					bottom: 0;left: 0;
					line-height: 22px;
					background: #FACD00;
					width: 110px;
					text-align: center;
					font-size: 1.6rem;
					z-index: 1;
				}
			}
			p{
				font-size: 1.6rem;
				b{
					display: block;
					font-size: 1.13em;
					line-height: 1;
					margin: 20px 0 10px 0;
				}
			}
			
		}
	}
	
	section.bg-GR{
		background: #EFEFEF;
		overflow: hidden;
		.inner{
			max-width: 1000px;
			margin: 0 auto;
		}
	}
	
	
	.note{
		font-size: 1.4rem;
	}
	
	
	
	table.nutrient{
		table-layout: fixed;
		width: 100% !important;
		height: auto !important;
		border-collapse: collapse;
		position: relative;
		caption{
			font-size: 1.6em;
			letter-spacing: .1em;
			position: absolute;
			top: -2em;
			right: 0;
		}
		th,td{
			border: 1px solid $line;
			padding: 20px;
			font-size: 1.6rem !important;
		}
		th{
			background: #EAF6FD;
			width: 200px;
		}
		td{
			font-size: 1.4rem;
			text-align: right;
		}
		& + table.material{
			margin-top: 50px;
		}
	}
	
	table.material{
		margin-top: 100px;
		width: 100%;
		border-collapse: collapse;
		th,td{
			border: 1px solid $line;
			padding: 20px;
		}
		th{
			background: #EAF6FD;
			font-size: 2rem;
			width: 200px;
		}
		td{
			font-size: 1.4rem;
		}
	}
	
	p.hd{
		line-height: 50px;
		text-align: center;
		font-size: 2rem;
		font-weight: 600;
		letter-spacing: .1em;
		border-radius: 25px;
		&.box{
			border-radius: 0;
		}
		&.border{
			border: 1px solid $text;
		}
	}
	
	section .inner > div.box{
		//background: #eee;
		padding: 50px 0;
		border-bottom: 1px dotted #333;
	}

	div.img-left{
		position: relative;
		.img{
			width: 250px;
			height: 250px;
			margin-left: 50px;
			img{
				width: 100% !important;
				height: auto !important;
				display: block;
			}
		}
		&.block{
			.img{
				.caption{
					font-size: 1.8em;
					font-weight: bold;
					line-height: 1;
					line-height: 30px;
				}
				width: 50%;
				height: auto;
				margin-left: 0;
			}
			.txt{
				left: 50%;
				width: 50%;
				padding-left: 50px;
				margin-top: 15px;
			}
		}
		.txt{
			//background: #eee;
			position:absolute;
			text-align: left;
			left: 350px;
			top: 50%;
			@include transform(translateY(-50%));
			h2{
				text-align: left;
				margin: 0;
				line-height: 1;
				margin-bottom: 20px;
			}
			p{
				text-align: left;
				@include pfix(.7em);
			}
		}
	}

	div.img-right{
		position: relative;
		.img{
			padding-left: 50%;
			text-align: center;
		}
		.txt{
			//background: #eee;
			position:absolute;
			text-align: left;
			left: 0;
			width: 50%;
			top: 50%;
			@include transform(translateY(-50%));
			h3{
				text-align: left;
				margin: 0;
				line-height: 1;
				font-size: 2.4rem;
				letter-spacing: .1em;
				@include minchou;
				font-weight: normal;
				margin-bottom: 50px;
			}
			p{
				text-align: left;
				@include pfix(.7em);
			}
		}
	}


	
}


/* !!------------------------------------ */
/* !! ★プレミアムクロワール茶 */


main.premium-croire{
	
	.mv{
		@include bg-img(url(../../product/img/bg01.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	.img.sod{
		text-align: center;
	}
	
	.desc{
		text-align: center;
		margin-top: 50px;
	}
	
	.result{
		position: relative;
		padding-right: 335px;
		.img{
			position: absolute;
			top: 0;right: 0;
			border: 1px solid $line;
		}
		.note{
			margin-top: 10px;
		}
		
		h3{
			color: #fff;
			line-height: 40px;
			background: #B81C22;
			font-size: 2rem;
			padding-left: 15px;
			margin: 50px 0 30px 0;
		}
		.red{
			color: #B81C22;
			font-size: 2rem;
			position: relative;
			padding-right: 240px;
			font-weight: bold;
			line-height: 1.4;
			margin-bottom: 20px;
			img{
				height: 50px;
				width: auto;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}
	
	.idx{
		display: flex;
		justify-content: space-between;
	}
	
	
	
	
}//main.croire



/* !!------------------------------------ */
/* !! ★クロワールアイ・プロ・Q */
main.croire-eye-pro,main.croire-eye-Q{
	
	.mv{
		@include bg-img(url(../../product/img/bg02.jpg)); 
		//$url,$size,$position,$repeat
		&.q{
			@include bg-img(url(../../product/img/bg02-q.jpg)); 
		}
	}
	
	&.croire-eye-Q h2{
		line-height: 1 !important;
		padding: 0px !important;
		border-bottom: 1px solid #ddd;
	}
	
	.idx{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&.rev{
			flex-direction: row-reverse;
		}
	}
	
	.graf{
		max-width: 900px;
		margin: 0 auto;
		margin-top: 50px;
		.txt{
			@include pc{
				width: 410px;
				height: 238px;
						
			}//@pc
			p{
				position: relative;
				top: 50%;
				margin-top: -20px;
				@include transform(translateY(-50%));
			}
		}
	}
	
	.img.ac{
		text-align: center;
	}
	
	p.ac{text-align: center;}
	
	
	
	h2.bd + p{
		padding-top: 30px;
		@include pfix(.7em);
	}
	
	img.KEMIN{
		vertical-align: middle;
		position: relative;
		top: -2px;
		margin-right: 20px;
		//top: 50%;
		//display: inline-block;
	}
	
	.mt30{
		margin-top: 30px;
	}
	
	.idx.box{
		dl{
			max-width: 475px;
			//width: 100%;
			margin: 0;
			dt{
				position: relative;
				text-align: center;
				padding-top: 20px;
				span{
					position: absolute;
					top: 0;left: 0;
					font-weight: bold;
					font-size: 2.2rem;
				}
			}
			dd{
				font-size: 1.6rem;
				padding-top: 20px;
				@include pfix(.7em);
			}
		}
	}
	
	ul.img-list._200{
		max-width: 800px;
		margin: 0 auto;
		li{
			@include sp{
				width: 50%;
				margin: 0;
				.img{
					width: 80%;
					margin: 0 auto;
				}
			}//@sp
			@include pc{
				width: 200px !important;
				margin:0 25px;
			}//@pc
			
			margin-bottom: 30px;
			&:nth-last-child(-n+3){
				margin-bottom: 0;
			}
			b{
				font-size: 2rem;
				font-weight: bold;
				line-height: 1;
				display: block;
				margin-bottom: 20px;
			}
			.img{
				height: auto;
			}
		}
	}
	
	ul.more-elem{
		font-size: 0;
		text-align: center;
		@include sp{
			@include flex;	
		}//@sp
		li{
			display: inline-block;
			letter-spacing: .1em;
			margin:0 5px;
			line-height: 60px;
			padding: 0 10px;
			min-width: 133px;
			text-align: center;
			font-size: 2rem;
			font-weight: bold;
			position: relative;
			@include sp{
				height: 50px;
				@include flex-centering;
				line-height: 1;
				width: calc(50% - 2px);
				margin: 0;
				margin-bottom: 4px;	
			}//@sp
			&.new{
				&:after{
					content: "";
					width: 100%;
					height: 36px;
					position: absolute;
					top: 0;left: 0;
					@include transform(translateY(-100%));
					@include bg-img(url(../../product/croire-eye-q/img/dec-NEW.png),auto 100%); //$url,$size,$position,$repeat					
				}
			}
		}
	}
	
	h2.Q{
		line-height: 1;
		padding: 0 !important;
		font-weight: bold !important;
		font-size: 26px;
		@include flex;
		justify-content: center;
		-ms-align-items: center;
		align-items: center;
		@include gothic;
		margin-bottom: 20px;
		span{
			height: 26px;
			border: 1px solid #000;
			display: inline-block;
			font-size: 14px;
			@include flex-centering;
			display: -webkit-inline-flex;
			display: -moz-inline-flex;
			display: -ms-inline-flex;
			display: -o-inline-flex;
			display: inline-flex;
			padding: 0 10px;
			margin-left: 10px;
			border-radius: 4px;
			@include sp{
				margin-top: 5px;	
			}//@sp
		}
	}
	
	/* !! ----- */
	/* !! Q */
	.lead.Q{
		max-width: 800px;
		margin: 0 auto;
		h3{
			margin: 50px 0 !important;
			max-width: 1000px;
			border: none;
			background: #000;
			color: #fff;
			font-size: 26px;
			@include sp{
				font-size: 20px;	
			}//@sp
			font-weight: 900;letter-spacing: .1em;
		}
		.idx{
			@include flex;
			@include sp{

			}//@sp
			
			.inner{
				height: auto;
				>i{
					position: absolute;
					width: 50px;height: 50px;
					font-size: 24px;
					@include sp{
						width: 36px;height: 36px;
						font-size: 20px;
					}//@sp
					position: absolute;
					top: 0;left: 0;
					@include transform(translate(-50%,-50%));
					z-index: 1;
					background: #000;
					@include flex-centering;
					color: #fff;
					font-weight: 900;
				}
				@include pc{
						width: calc(50% - 25px);
				}//@pc
				
				padding: 30px;
				@include sp{
					padding: 20px;
					margin-bottom: 30px;
				}//@sp
				position: relative;
				border: 1px solid $orange;
				margin: 0;
				text-align: justify;
				
				h4{
					font-size: 20px;
					color: $orange;
					font-weight: 900;
					line-height: 1.66;
				}
				p{
					font-size: 16px;
					text-align: justify !important;
					margin-top: 15px;
					@include sp{
						padding: 0 !important;	
					}//@sp
				}
			}
		}
	}//.lead.Q
	
	.graf-sec.Q{
		background: #ECF6FC;
		width: 100vw;
		h2{
			@include minchou;
			//border-bottom: 1px solid #777;
			line-height: 1.5 !important;
		}
		.idx{
			justify-content: center;
			max-width: 800px;
			margin: 0 auto;
			@include pc{
				@include flex;	
			}//@pc
			.inner{
				margin: 0 25px !important;
				width: calc(50% - 25px);
				
				@include pc{
					width: calc(50% - 25px);
					margin: 0 !important;
				}//@pc
				@include sp{
					width: 100%;
					margin: 0 !important;
					margin-bottom: 15px !important;	
				}//@sp
				h3{
					margin: 0 !important;
					background: #BA5D5C;
					color: #FFF;
					border: 0;
					text-align: center;
					height: 50px;
					@include flex-centering;
					font-size: 20px;
					@include sp{
						font-size: 20*$px;	
					}//@sp
				}
				padding: 0 !important;
			}			
		}
		.txt{
			text-align: center;
			margin-top: 15px;
			@include sp{
					img{
						width: 100% !important;
						height: auto !important;
					}
			}//@sp
		}
	}
	
	
	/* !!  - about-blue-light */
	.about-bluelight{
		padding-bottom: 0;
		.inner{
			padding: 0 !important;
		}
		
		border: 2px solid #e8e9e9;
		padding: 0;
		margin-top: 50px;
		.hd{
			margin: 30px 0;
			@include sp{
				padding: 0 15px;	
			}//@sp
			h3{
				margin: 0 !important;
				line-height: 1;
				font-size: 22px;
				color: #000;
				font-weight: 900;
				width: 100%;
				border: none;
				padding: 0;
				max-width: 1000px;
				text-align: center;
				@include sp{
					font-size: 18px;
					line-height: 1.5;	
				}//@sp
			}
		}
		p{
			@include flex;
			justify-content: center;
			span{
				padding:0px 15px;
				margin: 0 10px;
				height: 40px;
				border-radius: 4px;
				color: #fff;
				margin-top: 20px;
				@include flex-centering;
				display: -webkit-inline-flex;
				display: -moz-inline-flex;
				display: -ms-inline-flex;
				display: -o-inline-flex;
				display: inline-flex;
				font-size: 15px;
				letter-spacing: .1em;
				font-weight: bold;
				&._1{
					background: #CC592A;
				}
				&._2{
					background: #B42A2A;
				}
			}
		}
		
		.ft{
			text-align: center;
			margin: 30px 0;
			@include sp{
				padding: 0 15px;
			}//@sp
			h4{
				display: inline-block;
				span{
					border: 1px solid #000;
					@include flex-centering;
					font-size: 20px;
					font-weight: bold;height: 40px;
					@include sp{
						padding: 0 10px;	
					}//@sp
				}
				b{
					@include sp{
						font-size: 16px;	
					}//@sp
					@include pc{
						white-space: nowrap;
						font-size: 20px;
					}//@pc
					
					letter-spacing: .1em;
					display: block;
					margin-top: 15px;
				}
			}
		}
		
	}//.about-bluelight
	
	.total{
		font-size: 26px;
		text-align: center;
		line-height: 1.6;
		font-weight: 900;
		background: $orange;
		margin-top: 20px;
		padding: 20px;
		color: #FFF;
		@include sp{
			font-size: 18px !important;
			text-align: justify !important;
		}//@sp
	}
	
	
	
}//main.croire-eye-pro



/* !!------------------------------------ */
/* !! ★クロワールアイゴールド */
main.croire-gold{
	
	.mv{
		@include bg-img(url(../../product/img/bg06.jpg)); 
		//$url,$size,$position,$repeat
	}

}

/* !!------------------------------------ */
/* !! ★クロワールアイ・ルテイン+ゼアキサンチンゼリー */
main.croire-eye-lutein{
	
	.mv{
		@include bg-img(url(../../product/img/bg02.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	ul.img-list.mid.cen{
		li{
			width: 213px;
			span{
				white-space: nowrap;
			}
		}
	}

}

/* !!------------------------------------ */
/* !! ★Nアセチルグルコサミンを */
main.NA{
	
	.mv{
		@include bg-img(url(../../product/img/bg04.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	
	.lead{
		margin: 0 auto;
		padding: 0;
		.inner{
			
			padding: 100px 0 !important;
			border-bottom:1px solid $line;
		}
		.img{
			height: 156px;
			//background: #eee;
			@include flex-centering;
			._1{
				position: relative;
				top: 20px;
			}
		}
		.box{
			padding: 20px 0 !important;
			border: 2px solid #000 !important;
			border-radius: 10px;
			height: 160px;
			//background: #EEE;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
			align-content: center;
			h2{
				padding: 20px 0;
				text-align: center;
				@include gothic;
				font-weight: bold;
				width: 176px;
				margin: 0;
				border-right: 1px solid #000;
			}
			ul{
				text-align: left;
				font-size: 16px;
				padding: 0 20px;
				li{
					line-height: 24px;
					@include note();
					i{
						color: $kon;
					}
				}
			}
		}
	}
	
	div.idx{
		display: flex;
		justify-content: space-between;
	}
	
	section._1{
		h3{
			font-size: 43px;
			font-weight: 500 !important;
			color: $kon;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
			margin-bottom: 50px;
			strong{
				font-weight:700 !important;
				letter-spacing: .05em;
			}
			img{
				margin-right: 20px;
			}
			i{
				display: inline-block;
				padding-bottom: 3px;
				@include bg-img(url(../../product/NA/img/wave.png),28px 10px,bottom left,repeat-x); //$url,$size,$position,$repeat
			}
		}
		
		.txt{
			width: 50%;
			padding-left: 60px;
			@include flex-centering;
			position: relative;
			>img{
				position: absolute;
				left: 0;
				top: 50%;
				@include transform(translate(-50%,-50%));
			}
			.hd{
				text-align: center;
				width: 100%;
				p{
					text-align: center;
					font-size: 18px;
					padding: 15px 0;
					line-height: 1.56;
					position: .56em;
				}
				border-bottom: 1px solid $line;
			}
			h4{
				text-align: center;
				font-size: 24px;
				@include minchou;
				line-height: 1;
				
			}
			>p{
				padding-top: 15px;
				font-size: 16px;
				line-height: 28px;
				text-align: justify;
				@include pfix(.45em);
			}
		}
	}

}

/* !! - 成分 */
section.seibun{
	h2{
		text-align: center;
		color: $kon;
		font-size: 30px;
		line-height: 1;
		max-width: 1200px;
		margin: 0 auto;
		
		&:after{
			content: "";
			height: 1px;
			width: 100%;
			display: block;
			background: $kon;
			z-index: 0;
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));				
		}
		i{
			background: #fff;
			padding: 0 30px;
			@include gothic;
			font-weight: bold !important;
			position: relative;
			z-index: 1;
		}
	}
	
	.seibun{
		margin: 0 auto;
		border-radius: 10px;	
		border: 4px solid $kon;
		margin-top: 50px;
		max-width: 974px;
		position: relative;
		left: 13px;
		padding-left: 66px;
		padding-right: 24px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		position: relative;
		height: 310px;
		.num{
			position: absolute;
			top: 50%;left: 0;
			@include transform(translate(-50%,-50%));
			z-index: 1;
			width: 60px;height: 60px;
			border-radius: 50%;
			border: 4px solid $kon;
			font-size: 45px;
			line-height: 1;
			color: $kon;
			@include flex-centering;
			background: #fff;
			@include num;
		}
		.img{
			display: flex;
			align-items: flex-end;
		}
		.txt{
			width: 343px;
			@include flex-centering;
			
			h3{
				font-size: 22px;
				text-align: center;
				position: relative;
				width: 100%;
				@include minchou;
				line-height: 1;
				img{
					position: absolute;

					top: -22px;
					right: -22px;
				}
				i{
					font-size: 1.45em;
					display: block;
					width: 100%;
					padding-bottom: 5px;
					margin-bottom: 5px;
					border-bottom: 1px solid $line;
				}
			}
			p{
				font-size: 16px;
				line-height: 1.75;
				padding-top: 20px;
			}
			.pic{
				line-height: 1;
				color: #e73817;
				height: 1em;
				font-size: 18px;
				position: relative;
				text-align: left;
				width: 100%;
				padding-top: 0;
				letter-spacing: .1em;
				margin-top: 20px !important;
				img{
					position: absolute;
					margin-top: -10px;
					top: 50%;right: -30px;@include transform(translateY(-50%));
				}
			}
		}
		&._2{
			.img{
				align-items: center;
			}
		}
		&._3{
			.img{
				align-items: center;
				img{
					margin-left: -10px;
				}
			}
		}
	}//.seibun
}



/* !!------------------------------------ */
/* !! ★緑のDHA&EPA */
main.DHA-EPA{
	
	.mv{
		@include bg-img(url(../../product/DHA-EPA/img/mv-bg.jpg),cover,left bottom); 
		//$url,$size,$position,$repeat
	}

	.lead{
		h2{
			padding-bottom: 50px;
		}
		&._2{
			background: #F6F8E3;
		}
	}
	
	ul.img-list.mid.cen{
		li{
			width: 213px;
			span{
				white-space: nowrap;
			}
		}
	}
	
	
	.kodawari_wrap .inner{
		border-bottom: 1px solid $line;
	}

	.kodawari{
		position: relative;
		//overflow: hidden;
		height: auto !important;
		margin-bottom: 50px !important;
		&._1{
			justify-content: flex-start;
			align-items: center;
			p{
				max-width: 614px;
				margin-right: 50px;
			}
		}
		
		&.r{
			.txt{
				order:1;
			}
			.img{
				order: 2;
			}
		}
		
		.txt._3{
			overflow: hidden;
			width: 50%;
			margin: 0;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-content: center;
			align-items: center;
			height: auto;
			p{
				font-size: 22px;
				font-weight: bold;
				letter-spacing: .1em;
				top: 0;
				@include transform(translateY(0));
				strong{
					font-size: 1.36em;
					font-weight: bold;
					color: #E8380E;
					line-height: 1.3;
					i{
						font-size: 1.67em;
					}
				}
			}
		}


		.img{
			float: none;
		}
		ul{
			width: 50%;
			position: relative;
			@include flex-centering;
			align-items: center;
			align-content: center;
			//@include transform(translateY(-50%));
			li{
				width: 100%;
				background: #85CBBF;
				border-radius: 10px;
				padding: 15px;
				font-size: 1.6rem;
				font-weight: bold;
				color: #fff;
				margin-bottom: 15px;
				&:last-of-type{
					margin-bottom: 0;
				}
				p{
					@include note(1.5em);
					@include pfix(.6em);
				}
			}
		}
		&.left{
			.img{
				float: left;
				
			}
		}
	}
	
	p.point.red{
		color: #E8380E;
		text-align: center !important;
		font-size: 3rem;
		@include minchou;
		strong{
			@include bg-img(url(../../product/DHA-EPA/img/wave.png),auto 5px,center bottom,repeat-x); //$url,$size,$position,$repeat
			padding-bottom: 10px;
		}
	}


	section.mo{
		.inner{
			padding-bottom: 0;
		}
		h2{
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: center;
			align-items: center;
			i{
				font-weight: bold;
				margin: 0 10px;
				width: 64px;
				height: 64px;
				line-height: 1;
				display: inline-block;
				color: #FFF;
				@include flex-centering;
				display: -webkit-inline-flex;
				display: -moz-inline-flex;
				display: -ms-inline-flex;
				display: -o-inline-flex;
				display: inline-flex;
				@include bg-img(url(../../product/DHA-EPA/img/img2.png)); //$url,$size,$position,$repeat
			}
		}
		
		.hd{
			font-size: 24px;
			@include minchou;
			text-align:center;
			font-weight: normal;
			margin: 50px 0;
			line-height: 1;
		}
		
		.idx._1{
			dl{
				border: 1px solid #000;
				width: 475px;
				dt{
					font-size: 22px;
					background: #00437C;
					color: #fff;
					text-align: center;
					width: 250px;
					height: 50px;
					border-radius: 25px;
					@include flex-centering;
					margin: 0 auto;
					margin-top: -25px;
					font-weight: bold;
				}
				
				dd{
					font-size: 24px;
					width: 100%;
					padding:25px 0;
					@include flex-centering;
				}
			}
		}
		
		.img.ac{
			@include flex-centering;
			height: 100px;
		}
		
		.tBox{
			border: 1px solid #000;
			padding: 60px 110px;
			position: relative;
			margin-top: 100px;
			
			h3{
				background: #fff;
				width: 280px;
				margin: 0 auto;
				text-align: center;
				position: absolute;
				left: 50%;top: -100px;
				@include transform(translateX(-50%));
			}
			p{
				font-size: 16px;
				text-align: justify;
				strong{
					font-size: 24px;
					font-weight: bold;
					display: block;
					line-height: 1;
					margin-bottom: 25px;
					@include minchou;
					letter-spacing: .1em;
				}
			}
		}
		
	}//section.mo
	
	//!! - ベータヴィア（ユーグレナ）を配合！
	section.betavia{
		.inner{
			padding-bottom: 0;
		}
		h2{
			font-weight: bold;
			margin-bottom: 30px;
		}
		.lead{
			line-height: 1;
			padding: 0;
			font-weight: bold;
			font-size: 18px;
			letter-spacing: .1em;
		}
		.idx._1{
			align-items: center;
			p{
				width: 546px;
			}
			width: 780px;
			margin: 50px auto;
		}
		
		.feature{
			background: #F6F8E3;
			padding: 50px 110px;
			h3{
				font-size: 24px;
				text-align: center;
				font-weight: normal;
				padding-bottom: 30px;
				line-height: 1;
				border-bottom: 1px solid $line;
				letter-spacing: .1em;
				@include minchou;
			}
			ul{
				margin-top: 30px;
				font-weight: bold;
				font-size: 18px;
				line-height: 1.7;
				padding-top: 30px;
				@include pfix(.7em);
				li{
					font-size: 18px;
					letter-spacing: .1em;
					@include note;
					i{
						color: #8DC21E;
					}
				}
			}
		}
		
	}//section.betavia
	
	//!! - 現代の日本人に不足している成分「オメガ3」
	section.omega3{
		.inner{
			padding-bottom: 0;
		}
		.lead{
			padding: 0;
			text-align: justify;
		}
		
		.txt1{
			@include flex-centering;
			margin: 50px 0;
			margin-bottom: 80px;
			position: relative;
			.wrap{
				position: relative;
			}
			p{
				position: absolute;
				left: 0;
				height: 100%;
				width: 100%;
				top: 5px;
				@include flex-centering;
				font-size: 22px;line-height: 1.82;
				font-weight: bold;	
				text-align: center;
			}
		}
		
		.txt-wrap{
			position: relative;
			.txt{
				width: 573px;
				height: 210px;
				border: 1px solid #000;
				background: #fff;
				margin-left: auto;
				margin-right: 0;
				margin-top: -90px;
				position: relative;
				z-index: 1;
				@include flex-centering;
				padding: 0 50px;
				h3{
					font-size: 25px;
					width: 100%;
					text-align:center;
					@include minchou;
					line-height: 1;
					letter-spacing: .03em;
					padding-bottom: 20px;
					border-bottom: 1px solid #000;
				}
				p{
					font-size: 16px;
					line-height: 1.75;
					@include pfix(.75em);
					padding-top: 20px;
				}
			}
		}
		
	}//section.omega
	
}


/* !!------------------------------------ */
/* !! ★還元型コエンザイムQ10 */
main.Q10{
	
	h2{
		@include minchou;
	}
	
	.mv{
		@include bg-img(url(../../product/Q10/img/mv-bg.jpg)); 
		//$url,$size,$position,$repeat
	}
	
	div.img-right.block{
		margin-top: 100px;
		.txt{
			padding-right: 50px;
		}
		h3{
			line-height: 1.75;
			@include pfix(.75em);
			padding-bottom: 50px;
		}
		h4{
			font-size: 2rem;
			line-height: 1;
			margin-bottom: 20px;
		}
	}
	
	div.img-left.block{
		.txt{
			margin-top: 0;
		}
	}
	
	.img-list li{
		margin-bottom: 0 !important;
	}
	
	p.ac{
		text-align: center;
	}
	
	ul.img-list.food{
		max-width: 600px;
		margin: 0 auto;
		li{
			margin: 0 25px 50px 25px !important;
			&:nth-last-of-type(-n+3){
				margin-bottom: 0 !important;
			}
		}
	}
	
	p.hd{
		font-size: 2rem;
		line-height: 1;
		margin-bottom: 20px;
		strong{
			font-size: 1.2em;
			font-weight: 600;
			display: block;
			margin-bottom: 20px;
		}
	}
	
	ul.idx.vitamin{
		margin: 0 auto;
		text-align: center;
		margin-bottom: -25px;
		li{
			max-width: 415px;
			display: inline-block;
			width: 100%;
			height: 200px;
			position: relative;
			margin: 25px;
			border: 1px solid #fff;
			border-radius: 10px;
			p{
				text-align: center;
				font-size: 3rem;
				font-weight: 600;
				line-height:1.33;
				position: relative;
				color: #fff;
				top: 50%;
				@include pfix(.33em);
				@include transform(translateY(-50%));
				span{
					display: block;
					font-size: 0.5em;
				}
			}
		}
		
	}

}

/* !!------------------------------------ */
/* !! 魔法のワハハ歯ブラシ */
main.wahaha{
	
	.mv{
		background-color: #fff;
		@include bg-img(url(../../product/wahaha-haburashi/img/mv.jpg)); //$url,$size,$position,$repeat
		-webkit-background-size: 1366px auto !important;
		background-size: 1366px auto !important; 
	}
	
	.block{
		margin-top: 100px;
		.inner{
			position: relative;
			border: 1px solid $text;
			padding: 80px 0 !important;
			h2{
				position: absolute;
				@include minchou;
				top: 0;left: 0;
				width: 100%;
				height: 60px;
				@include flex-centering;
				@include transform(translateY(-50%));
				i{
					display: block;
					background: #fff;
					padding: 0 30px;
					letter-spacing: .1em;
				}
			}
		}
	}
	
	#_1{
		margin-top: 150px;
		ul.idx{
			max-width: 690px;
			margin: 0 auto;
			li{
				width: 150px;
				height: 50px;
				font-size: 20px;
				font-weight: bold;
				color: #fff;
				margin-bottom: 30px;
				background: #656464;
				@include flex-centering;
				border-radius: 5px;
				&.b{
					background-color: #1c2585;
				}
				&.r{
					background-color: #8c1d25;
				}
				&:nth-of-type(n+5){
					margin-bottom: 0;
				}
			}
		}
	}
	
	#_2{
		p{
			text-align: center;
		}
	}
	
	
	#_3{
		max-width: 1000px;
		margin: 100px auto;
		position: relative;
		._1{
			line-height: 1;
			position: absolute;
			bottom: 60px;
			left: 0;
			width: 100%;
			text-align: center;
			z-index: 1;
			strong{
				display: inline-block;
				font-weight: bold;
				font-size: 18px;
				position: relative;
				img{
					position: absolute;
					left: -10px;
					top: 50%;
					@include transform(translate(-100%,-50%));
				}
			}
		}
	}
	
	#_4{
		.inner{
			max-width: 1000px;
			padding: 70px 100px !important;
		}
		
		p._1{
			@include minchou;
			font-size: 20px;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
		}
		
		.img{
			position: relative;
			p{
				right: 150px;
				line-height: 1;
				text-align: right;
				bottom: 0;
				position: absolute;white-space: nowrap;
				font-weight:900;
				font-style: 18px;
				i{
					display: block;
					font-size: 14px;
					margin-bottom: 5px;
				}
			}
		}
		
		h3{
			text-align: center;
			max-width: 500px;
			margin: 50px auto;
			&:after{
				content: "";
				height: 1px;
				width: 100%;
				display: block;
				background: #333;
				@include transform(translateY(-15px));	
			}
			i{
				display: inline-block;
				position: relative;
				z-index: 1;
				line-height: 30px;
				background: #fff;
				font-size: 18px;
				letter-spacing: .1em;
				padding: 0 30px;
				@include minchou;
			}
		}
		
		div._1{
			background: #E9EFF8;
			padding: 50px;
			p{
				@include minchou;
				margin: 0;
			}
		}
	}
	
	div.sec{
		max-width: 580px;
		margin: 100px auto;
		border: 1px solid $text;
		height: 95px;
		border-radius: 10.5px;
		align-items: center;
		h2{
			width: 176px;
			height: 54px;
			border-right: 1px solid $text;
			@include gothic;
			font-weight: 900;
			@include flex-centering;
		}
		ul{
			max-width: 376px;
			li{
				position: relative;
				@include note;
				font-size: 16px;
				i{
					color: #0B308D;
				}
			}
		}
	}
	
	
	#_5{
		.img{
			text-align: center;
			margin: 100px 0;
		}
		
		.howto{
			border: 1px solid #8F1C23;
			max-width: 1000px;
			margin: 100px auto;
			h3{
				background: #8F1C23;
				color: #fff;
				height: 80px;
				font-size: 22px;
				@include flex-centering;
				letter-spacing: .1em;
			}
			.txt{
				padding: 50px 100px;
				align-items: center;
			}
			p{
				max-width: 616px;
			}
			.img{
				margin: 0;
			}
		}
	}
	
	#_6{
		.inner{
			max-width: 1000px;
			background: #F5F2E9;
			border: 2px solid #000;
			position: relative;
			padding: 50px;
			-ms-align-items: center;
			align-items: center;
			&:before{
				content:"";
				@include innerRect(5px);
				border: 1px solid #000;
				pointer-events: none;
			}	
		}
		.img._1{
			position: relative;
			img{
				//width: 120%;
			}
		}
		.txt{
			.hd{
				line-height: 1;
				font-size: 16px;
				margin-bottom: 20px;
				font-weight: bold;
			}
			ul{
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
	
	
	.nutrient{
		margin: 100px auto;
		max-width: 1000px;
	}
	
	
}//main.wahaha



/* !!------------------------------------ */
/* !! 安心・安全への取り組み */

main.forsafety{
	
	>section{
		position: relative;
		&:first-of-type{
			margin-top: 100px;
		}
		
		padding: 0;
		margin: 0;
		height: 450px;
		position: relative;
    &.height-auto{
      height: auto;
    }
		h2{
			padding-left: 0;
			&:after{
				content: none;
			}
			& + p{
				margin-top: 15px;
				white-space: nowrap;
			}
		}
		
		.wrap{
			position: absolute;
			z-index: 1;
			left: 100px;top: 50%;
			@include transform(translateY(-50%));
		}
    

		
		.img{
			position: absolute;
			top: 0;right: 0;
		}
		&.kokunai{
			@include gd-1;
		}
		
		&.seibun{
			.img{
				left: 0;
				right: auto;
				z-index: 0;
			}
			background: -webkit-linear-gradient(90deg ,#f9f9f9 0%, #fefefe 100%);
			.wrap{
				left: auto;
				right: 100px !important;
			}
		}
		&.privacy{
			@include gd-2;
			margin-bottom: 100px;
			.img{
				position: absolute;
				top: 0;right: 0;
			}
		}

    
	}
	
	
	
}//main.forsafety


/* !!------------------------------------ */
/* !! 持続可能な開発目標 */

main.SDGs{
  .mv{
		margin-top: 50px;
		height: 480px;
		padding: 0;
		overflow: hidden;
		@include bg-img(url(../../SDGs/img/mv.jpg),cover); //$url,$size,$position,$repeat
    @include sp{
      height: 200px;
    }
		p{
			width: 775px;
			height: 145px;
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%,-50%));
			img{
				position: absolute;
				width: 100% !important;
				height: auto !important;
				top: 0;left: 0;
			}
		}
	}
}

$col_sdgs :#009DDB;
section.SDGs{
  max-width: 1200px;
  margin: 0 auto;
  @include sp{
   // background: rgba($col_sdgs,.1);
  }
  h2.label{
    background: #efefef;
    border-left: 6px solid $col_sdgs;
    line-height: 1;
    padding: 10px 0;
    padding-left: 12px;
    margin-bottom: 40px;
    @include sp{
      margin: 20px 0;
    }
  }
  .lead{
    font-size: 16px;
    line-height: 2;
  }
  @include sp{
    padding: 0 !important;
  }
  .inner{
    padding: 50px 0;
    @include sp{
      padding: 15px;
    }
  }
  .flx{
    
    @include pc{
      display: flex;
      justify-content: space-between;
    }
    p{white-space : normal;}
    h4{
      font-size: 18px;
      background: #eee;
      text-align: center;
      line-height: 1;
      padding: 5px 0;
      //margin: 30px 0 15px 0;
    }

    &.hd{
      border-bottom: 0 !important;
      padding: 0 !important;
      >div{
        background:rgba($col_sdgs,0.1);
        border-radius: 4px;
        line-height: 1;
        p{ 
          text-align: center;
          padding: 5px;
          font-size: 18px;
          color: $col_sdgs;
          font-weight: 600;
          letter-spacing: 0.1em;
          
        }
      }
    }
    &.col3{
      border-bottom: 2px solid #ddd;
      padding: 30px 0;
      
      
      @include pc{
        >div{
          width: calc((100% - 30px) /3 );
        }
        display: flex;
        align-items: center;
      }
      @include sp{
       border: 3px solid #eee;
       padding: 15px;
       margin-bottom: 15px;
       >div{
         margin-bottom: 20px;
        &:last-of-type{
          margin-bottom: 0;
        }
       }
      }
      h3{
        text-align: center;
        font-size: 20px;
        letter-spacing: 0.1em;
        @include sp{
          text-align: justify;
        }
      }
      h4{
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        background: none;

        &+ul{
          font-size: 16px;
          font-weight: bold;
          
          li{
            margin: 5px 0;
            position: relative;
            padding-left: 1.25em;
            i{
              color: $col_sdgs;
              position: absolute;
              top: .25em;left: 0;
            }
          }
        }
      }

    }

    .icon-wrapper {
      text-align: center;
      font-size: 0;
      ul.img{
        @include flex;
        width: auto;
        justify-content: center;
        display: inline-flex;
        padding: 5px;
        li{
          width: 110px;height: 110px;
          margin: 5px;
          
        }
        @include sp{
          padding: 0;
          justify-content: space-between;
          li{
            width: calc((100% - 20px) / 3);
            height: auto;
            margin: 0;
            margin-bottom: 10px ;
            &:nth-last-child(-n+3){
              margin-bottom: 0;
            }
          }
        }
      }
    }

    

    >div{
      
      background: #fff;
     
      @include pc{
        //padding: 30px;
        width:calc(49%);
      }
      @include sp{
        //padding: 15px;
        margin-bottom: 0px;
      }
      .img{
        position: relative;
        @include sp{
          width: 100% !important;
        }
        img{
          display: block;width: 100%;
          height: auto;
        }
      }
    }

    p.label.sp{
      background: $col_sdgs;
      border-radius: 50px;
      width: 120px;
      padding: 5px 10px;
      text-align: center;
      color: #fff;
      font-size: 13px;
      line-height: 1;
      white-space : nowrap ;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}


/* !!------------------------------------ */
/* !! 企業情報 */

main.company{
	
	.lead-wrap{
		max-width: 1000px;
		position: relative;
		margin: 100px auto;
		.img{
			position: absolute;
			top: -136px;
			left: -180px;
			z-index: 0;
		}
		.lead{
			position: relative;
			z-index: 1;
			margin-left: 300px;
			background: #EAF6FD;
			padding: 50px;
			p{
				font-size: 1.6rem;
				line-height: 1.75;
				@include pfix(.75);
        margin-bottom: .5em;
        &:last-of-type{
          margin-bottom: 0;
        }
			}
      .signiture{
        margin-top: 30px;
        text-align: right;
        img{
          width: 120px;
          height: auto;

        }
        p{
          text-align: right;
          font-size: 12px;
        }
      }
		}
	}
	
	h2.cen{
		text-align: center;
		padding: 0 !important;
		margin-bottom: 50px;
		line-height: 1;
		&:after{
			content: none;
			
		}
	}
	
	.philosophy{
		padding: 0;
    @include pc{
      margin-bottom: 50px;
    }
		.inner{
			width: 1000px;
			margin: 0 auto;
		}
		
		
		ul{
			display: flex;
			justify-content: space-between;
			li{
				width: 300px;
				border-bottom: 1px solid $line !important;
				strong{
					line-height: 1;
					display: block;
					border: 1px solid $line;
					border-width: 1px 0;
					font-size: 1.8rem;
					line-height: 40px;
					text-align: center;
					font-weight: 600;
				}
				p{
					padding: 30px 0;
					@include pfix(.75em);
				}
			}
		}
		
	}//.philosophy
	
	
	//!! 会社概要
	ul.outline{
		li{
			height: 50px;
			div{
				position: relative;
				line-height: 1;
				font-size: 1.6rem;
				max-width: 1000px;
				margin: 0 auto;
				top: 50%;
				@include transform(translateY(-50%));
				span{
					display: inline-block;
					width: 136px;
				}
			}
			&:nth-of-type(2n + 1){
				background: #F7F8F8;
			}
		}
	}
	
	
}//main.company




/* !!------------------------------------ */
/* !! コンタクト */

main.contact{
	
	section#form-sec{
		text-align: center;
		padding-bottom: 0;
	}
	
	p.hd{
		text-align: center;
		font-size: 1.8rem;
		font-weight: 600;
		line-height: 1;
		margin-bottom: 0px;
	}
	
	
	.mfp_err{
		line-height: 1;
		padding:8px 0 !important;
		//display: block !important;
		white-space: nowrap;
		&:before{
			content: "※";
		}
	}
	.cb-grp{
		width: 800px;
		margin: 0px auto !important;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		& + .mfp_err{
			text-align: center;
		}
		>div{
			width: 385px;
			height: 70px;
			margin-top: 30px;
			background: #F7F8F8;
			margin-bottom: 0;
			&:last-of-type(-n + 3){
				margin-bottom: 30px !important;
			}
			label{
				display: block;
				position: relative;	
				line-height: 1;
				padding-left: 30px;
				text-align: left;
				line-height: 1;
				font-size: 1.6rem;
				top: 50%;
				input{
					display: inline-block;
					margin-right: 10px;
				}
				@include transform(translateY(-50%));
			}
		}
	}
	
	.contact-wrap{
		background: #F7F8F8;
		padding: 50px 0;
		margin-top: 30px;
	}
	
	ul.form-grp{
		display: flex;
		flex-wrap: wrap;
		width: 800px;
		margin: 30px auto;
		justify-content: space-between;
		li{
			width: 385px;
			height: 50px;
			margin-bottom: 50px;
			position: relative;
			font-size: 1.6rem;
			span{
				position: absolute;
				top: 0;left: 0;
				
				@include transform(translateY(-100%));
				i{
					display: inline-block;
					color: #f34c4c;
					border: 2px solid #f34c4c;
					line-height: 1;
					padding: 2px;
					border-radius: 4px;
					font-size: 1.2rem;
					margin-left: 10px;
					position: relative;
					top: -2px;
					font-weight: bold;
				}
			}
			input{
				appearance: none;
				padding: 10px;
				border: 1px solid $line;
				height: 100%;
				width: 100%;
				display: block;
				font-size: 1.6rem;
			}
			&.full{
				width: 100% !important;
				.zip{
					height: 50px;
					width: 200px;
					float: left;
					position: relative;
					&:before{
						content: "〒";
						position: absolute;
						bottom: 0;left: 0;
						width: 40px;
						text-align: center;
						line-height: 50px;
					}
					input{
						padding-left: 40px;
					}
				}
				div.add{
					width: 575px;
					height: 50px;
					float: right;
					clear: right
				}
			}
			&.inquiry{
				width: 100% !important;
				height: auto;
				margin-bottom: 0 !important;
				textarea{
					padding: 10px;
					font-size: 1.6rem !important; 
					border: 1px solid $line;
					display: block;
					max-width: 1000px;
					width: 100% !important;
					height: 300px !important;
				}
			}
		}
	}
	
	.bt_wrap{
		font-size: 0;
		button{
			position: relative;
			appearance: none;
			border: none;
			width: 300px;
			height: 50px;
			line-height: 50px;
			background: #666;
			color: #fff;
			font-size: 1.6rem;
			font-weight: bold;
			letter-spacing: .1em;
			padding: 0;
			margin: 0 15px;
			&:after{
				@include anchor(#fff,8px);
			}
			&.submit{
				@include gd-BLU;
			}
		}
	}
	
	
}//main.contact



/* !! - - -  */
/* !! 確認画面 */
table#mfp_confirm_table{
	max-width: 800px;
	width: 100%;
	border: 1px solid $line;
	margin: 50px auto;
	margin-bottom: 30px;
}


.mfp_buttons{
	margin-bottom: 50px;
	button{
		position: relative;
		appearance: none;
		border: none;
		width: 300px;
		height: 50px;
		line-height: 50px;
		background: #666;
		color: #fff;
		font-size: 1.6rem;
		font-weight: bold;
		letter-spacing: .1em;
		padding: 0;
		margin: 0 15px;
		&:after{
			@include anchor(#fff,8px);
		}
		&#mfp_button_send{
			@include gd-BLU;
		}

	}
}



/* !! - - -  */
/* !! 完了画面 */
div.thanks{
	border-radius: 10px;
	width: 600px;
	padding: 50px;
	background: #F7F8F8;
	margin: 100px auto;
	margin-bottom: 50px;
	& + .btn{
		margin: 0 auto;
		margin-bottom: 100px;
	}
}
